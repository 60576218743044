/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment, useEffect, useState } from 'react';
import { ElementState } from '../../renderer/ElementState';
import { ImagePreset } from './ImagePreset';
import { PropertyCaption } from './PropertyCaption';
import { PropertySelect } from './PropertySelect';
import { PropertyNumericalInput } from './PropertyNumericalInput';
import { ElementAnimationSettings } from './ElementAnimationSettings';
import { GenericAnimationSettings } from './GenericAnimationSettings';
import styled from 'styled-components';
import lodash from 'lodash';
import { numericalInputStyles } from '../../styles/mainStyle';
import { videoCreator } from '../../stores/VideoCreatorStore';
import FadeProducer from '../../fadeEffectProcessor/FadeProducer';
import ImageFitPropertySelect from './ImageFitPropertySelect';
import UpDownIcon from '../../svgs/UpDownIcon';
import SliderSelect from '../common/PlainSlider';

interface ImageSettingsProps {
  activeElement: ElementState;
}
const DEFAULT_FIT_PROPS = [
  { caption: 'Cover', value: 'cover' },
  { caption: 'Contain', value: 'contain' },
  {
    caption: 'Black Frame',
    value: 'black-frame',
  },
  { caption: 'Fill', value: 'fill' },
];

export const ImageSettings: React.FC<ImageSettingsProps> = (props) => {
  const fadeProducer = new FadeProducer(props.activeElement);
  const [imageFitProps, setImageFitProps] = useState(DEFAULT_FIT_PROPS);

  useEffect(() => {
    if (videoCreator.currentVideo?.aspectRatio === '9:16') {
      setImageFitProps(imageFitProps.filter((f) => f.value !== 'black-frame'));
    } else setImageFitProps(DEFAULT_FIT_PROPS);
  }, [videoCreator.currentVideo?.aspectRatio]);

  const modifyPropertyDebounced = lodash.debounce(
    async (propertyName: string, propertyValue: any) => {
      const actionLabel = `changing image ${
        propertyName === 'y_alignment' ? 'position' : 'settings'
      }`;
      await videoCreator.applyVideoStateModifications(
        {
          [`${props.activeElement?.source.id}.${propertyName}`]: propertyValue,
        },
        true,
        actionLabel,
      );
    },
    250,
  );

  return (
    <Fragment>
      <ImagePreset
        activeElement={props.activeElement}
        url={props.activeElement.source.source}
      />

      <ElementAnimationSettings activeElement={props.activeElement} />

      <BasicContent>
        <Item>
          <PropertyCaption>Start</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="time"
            defaultValue="auto"
            unit="s"
            customStyles={numericalInputStyles}
            getInputValue={async (property: string, value: string) => {
              const overlayData = await fadeProducer.resetCrossfadeOnVideo(
                parseFloat(value),
                props.activeElement.duration,
              );

              await videoCreator.applyVideoStateModifications(
                {
                  ...overlayData,
                  [`${props.activeElement?.source.id}.${property}`]: value,
                },
                true,
                'changing image placement',
              );

              videoCreator.handleResetPhotoHighlight(
                props.activeElement,
                value,
              );
            }}
          />
        </Item>

        <Item>
          <PropertyCaption>Duration</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="duration"
            defaultValue="auto"
            unit="s"
            customStyles={numericalInputStyles}
            getInputValue={async (property: string, value: string) => {
              const overlayData = await fadeProducer.resetCrossfadeOnVideo(
                props.activeElement.source.time,
                parseFloat(value),
              );

              await videoCreator.applyVideoStateModifications(
                {
                  ...overlayData,
                  [`${props.activeElement?.source.id}.${property}`]: value,
                  ...videoCreator.imageCompositionDurationModifications(
                    props.activeElement,
                    parseFloat(value),
                  ),
                },
                true,
                'changing image placement',
              );

              videoCreator.handleResetPhotoHighlight(
                props.activeElement,
                null,
                value,
              );
            }}
          />
        </Item>

        <Item>
          <PropertyCaption>Fit</PropertyCaption>
          <ImageFitPropertySelect
            activeElement={props.activeElement}
            propertyName="fit"
            defaultValue="cover"
            options={imageFitProps}
          />
        </Item>

        <Item>
          <PropertyCaption>Opacity</PropertyCaption>
          <PropertySelect
            activeElement={props.activeElement}
            propertyName="opacity"
            defaultValue="100%"
            options={[
              { caption: 'None', value: '100%' },
              { caption: '20%', value: '20%' },
              { caption: '40%', value: '40%' },
              { caption: '60%', value: '60%' },
              { caption: '80%', value: '80%' },
            ]}
          />
        </Item>

        {props.activeElement.source.type === 'image' && (
          <Item>
            <PropertyCaption>Y-Alignment</PropertyCaption>
            <SliderAndIcon>
              <UpDownIcon />
              <SliderSelect
                getValue={(newValue) =>
                  modifyPropertyDebounced('y_alignment', newValue + '%')
                }
                defaultValue={50}
                currValue={parseFloat(props.activeElement.source.y_alignment)}
                min={0}
                max={100}
              />
            </SliderAndIcon>
          </Item>
        )}
      </BasicContent>

      <GenericAnimationSettings activeElement={props.activeElement} />
    </Fragment>
  );
};

// const Information = styled.div`
//   margin-top: 20px;
//   color: #a3a5a5;

//   a {
//     color: #a3a5a5;
//   }
// `;

const BasicContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
`;

const Item = styled.div``;

const SliderAndIcon = styled.div`
  display: flex;
  border: 1px solid #484848;
  border-radius: 8px;
  padding: 12px;
  gap: 5px;
  align-items: center;
  height: 15px;
`;
