import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import styled, { css } from 'styled-components';
import { scrollIfNotInView } from '../../utility/general';

type Props = {
  values: { caption: string; value: string | null }[];
  styleFont?: boolean;
  width?: string;
  action: (propertyName: string, value: string | null) => void;
  propertyName: string;
  defaultValue: string | undefined | null;
  getValue?: (value: string) => void;
  hasStickyBottom?: boolean;
};

const ItemDropdownSelect = forwardRef((props: Props, ref) => {
  const { width = undefined } = props;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [selectedItem, setSelectedItem] = useState<string | undefined | null>(
    props.defaultValue,
  );
  const [showDropdown, toggleDropdown] = useState<boolean>(false);

  useEffect(() => {
    setSelectedItem(props.defaultValue);
  }, [props.defaultValue]);

  const contentRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(contentRef, () => {
    toggleDropdown(false);
  });

  useEffect(() => {
    if (showDropdown && dropdownRef.current && ref) {
      const container = (ref as React.RefObject<HTMLDivElement>)
        .current as Element;

      const stickyContainerHeight = props.hasStickyBottom ? 100 : 0;

      scrollIfNotInView(dropdownRef.current, container, stickyContainerHeight);
    }
  }, [showDropdown]);

  const ArrowDownIcon = (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none">
      <path d="M2 4.33008L6 8.33008L10 4.33008" stroke="#F2D093" />
    </svg>
  );

  const selectedValue = props.values.find((v) => v.value === selectedItem);

  const renderDropdownItem = (item: {
    caption: string;
    value: string | null;
  }) => (
    <DropdownItem
      styleFont={props.styleFont}
      item={item.value}
      key={item.value}
      onClick={() => {
        setSelectedItem(item.value);
        if (props.getValue) {
          props.getValue(item.value!);
        }

        props.action(props.propertyName, item.value);
        toggleDropdown(false);
      }}
    >
      {item.caption}
    </DropdownItem>
  );

  return (
    <Main width={width} ref={contentRef}>
      <Selected
        width={width}
        onClick={(e) => {
          toggleDropdown(!showDropdown);
        }}
      >
        <SelectedContent
          styleFont={props.styleFont}
          selectedItem={selectedItem}
        >
          {selectedValue?.caption || ''}
        </SelectedContent>{' '}
        {<ToggleIcon>{ArrowDownIcon}</ToggleIcon>}
      </Selected>

      {showDropdown && (
        <Dropdown width={width} ref={dropdownRef}>
          {props.values.map((item) => renderDropdownItem(item))}
        </Dropdown>
      )}
    </Main>
  );
});

export default ItemDropdownSelect;

const Main = styled.div<{
  width?: string;
}>`
  position: relative;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
`;
const Selected = styled.div<{
  width?: string;
}>`
  border: 1px solid #484848;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  cursor: pointer;

  padding: 8px 16px;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
`;

const SelectedContent = styled.span<{
  styleFont?: boolean;
  selectedItem?: string | null;
}>`
  font-size: 14px;
  ${(props) =>
    props.styleFont &&
    props.selectedItem &&
    css`
      font-family: ${props.selectedItem};
    `}

  ${(props) =>
    !props.styleFont &&
    css`
      font-family: 'Inter', sans-serif;
    `}
`;
const Dropdown = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  border: 1px solid #484848;
  border-radius: 8px;
  background-color: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  padding: 5px 10px;
  max-height: 200px;
  width: calc(100% - 20px);
  overflow: auto;
  position: absolute;

  cursor: pointer;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const DropdownItem = styled.span<{
  styleFont?: boolean;
  showColor?: boolean;
  item?: string | null;
}>`
  padding: 12px;
  font-size: 14px;
  ${(props) =>
    props.styleFont &&
    props.item &&
    css`
      font-family: ${props.item};
      padding: 12px;
    `}

  ${(props) =>
    !props.styleFont &&
    css`
      font-family: 'Inter', sans-serif;
    `}

    ${(props) =>
    props.item === 'transparent' &&
    css`
      background: linear-gradient(
        to left top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) calc(50% - 0.8px),
        rgb(255, 0, 0) 50%,
        rgba(255, 255, 255, 1) calc(50% + 0.8px),
        rgba(255, 255, 255, 1) 100%
      );
    `}

  &:not(:last-child) {
    border-bottom: ${(props) => !props.showColor && '1px solid #484848'};
  }
  &:hover {
    color: #f2d093;
  }
`;

const ToggleIcon = styled.button`
  border: 0;
  outline: 0;
  background-color: transparent;
`;
