import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import EllipsisIcon from '../../svgs/EllipsisIcon';
import ShareIcon from '../../svgs/ShareIcon';
import QuoteStartIcon from '../../svgs/QuoteStartIcon';
import { videoCreator } from '../../stores/VideoCreatorStore';
import PhotoModal from '../common/PhotosModal';
import Modal from '../Modal';
import { ImageKey, ImageWithType } from '../../types.ts/general';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import CameraIcon from '../../svgs/CameraIcon';
import SpinningLoading from '../SpinningLoading';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { getRandomFileName } from '../../utility/general';
import { AiGeneratedContent, Artifact, Caption, FileData, ShareableImageType, SharedContent } from '../../types.ts/story';
import SharableImagesModal from './SharableImagesModal';
import PhotoModalTop from '../common/PhotoModalTop';
import { ActionButton, ActionsWrapper } from '../../styles/mainStyle';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import SocialStats from './SocialStats';
import SocialShareStatus from './SocialShareStatus';

type Props = {
  id: string;
  text: string;
  imageFile: Artifact | undefined;
  isSaved: boolean;
  setLoadingStock: Dispatch<SetStateAction<boolean>>;
  allReferencingSharedContents?: SharedContent[];
  allReferencingCaptions?: Caption[]
};

const QuoteCard = observer((props: Props) => {
  let { text, allReferencingSharedContents, allReferencingCaptions } = props;
  const { story } = videoCreator;
  const storyTeller = story?.storyTeller?.name;
  const [openMedia, toggleMedia] = useState<boolean>(false);
  const [openDropdown, toggleDropdown] = useState<boolean>(false);

  const [selectedImage, setSelectedImage] = useState<
    ImageWithType[ImageKey] | null
  >(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [isSavingLoading, setIsSavingLoading] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const alreadySaved = story?.shareableImages || [];
  const [sharablePost, toggleShare] = useState<ShareableImageType | null>(null);
  const background = props.imageFile?.url

  const {
    contentStudioEnableShareableImageSave,
    contentStudioEnableImageShare,
  } = useFlagsCombination();

  useOutsideAlerter(buttonRef, () => {
    toggleDropdown(false);
  });

  const updateImage =
    (image: Artifact | undefined, uploadId: string, url: string) => {
      return {
        ...(image || {}),
        id: uploadId,
        url,
        responsiveImage: {
          ...(image?.responsiveImage || {}),
          srcSet: url
        }
      }
    }

  const replaceAction = async () => {
    if (selectedImage) {
      try {
        const isInDato = selectedImage.url.startsWith(
          'https://www.datocms-assets.com',
        );
        let url = selectedImage.url;
        let uploadId: string = selectedImage.id

        if (!isInDato) {
          props.setLoadingStock(true);
          toggleMedia(false);
          const fileName = getRandomFileName(text);

          const newPhotoData: FileData & { fileName: string } = {
            type: 'stock',
            url: selectedImage.url,
            fileName,
            alt: text,
            title: text,
          };

          const newUpload =
            await videoCreator.assetRepository?.uploadFile(newPhotoData);
          if (newUpload) {
            url = newUpload.url
            uploadId = newUpload.id
          }
        }

        if (uploadId && url) {
          if (props.isSaved) {
            const saved = (story?.shareableImages || []).map((s) => {
              if (s.id === props.id) {
                return {
                  ...s,
                  imagefile: updateImage(s.imagefile, uploadId, url)
                } as ShareableImageType
              }
              return s;
            });
            runInAction(() => {
              videoCreator.story!.shareableImages = saved
            });
          } else {
            const unsaved = (videoCreator.unsavedShareableImages || []).map((o) => {
              if (o.id === props.id) {
                return {
                  ...o,
                  imagefile: updateImage(o.imagefile, uploadId, url)
                };
              }
              return o;
            });
            runInAction(() => {
              videoCreator.unsavedShareableImages = unsaved as Omit<ShareableImageType, '_allReferencingSharedContents'>[];
            });
          }
        }

      } catch (error) {
        console.log('Error occurred in replace action: ', error);
      } finally {
        toggleMedia(false);
        props.setLoadingStock(false);
      }
    }
  };

  const saveShareableImage = async () => {
    if (!story) return;

    try {
      setIsSavingLoading(true);
      const data = {
        id: props.id,
        imagefile: props.imageFile,
        quote: text,
      };
      toggleDropdown(false);


      if (!props.isSaved) {
        const savedId = await videoCreator.storyRepository?.createShareableImage(
          story,
          data.quote,
          data.imagefile,
        );

        videoCreator.story!.shareableImages = [
          ...alreadySaved,
          {
            ...data,
            id: savedId as string
          }
        ] as ShareableImageType[]

        videoCreator.unsavedShareableImages = videoCreator.unsavedShareableImages?.filter(o => o.id !== data.id) || []
      } else {
        await videoCreator.storyRepository?.updateShareableImage(data.id, data.quote, data.imagefile);

        videoCreator.story!.shareableImages = alreadySaved.map(s => {
          if (s.id === data.id) {
            return {
              ...s,
              quote: data.quote,
              imagefile: data.imagefile
            }
          }
          return s
        }) || []
      }

      await videoCreator.storyRepository?.update(videoCreator.story!);

    } catch (error) {
      console.log('Error occurred in saving sharable image: ', error);
    } finally {
      setIsSavingLoading(false);
      toggleDropdown(false);
    }
  };

  const deleteShareableImage = async () => {
    if (!story) return;

    try {
      setIsDeleteLoading(true);
      toggleDropdown(false);

      if (props.isSaved) {
        videoCreator.story!.shareableImages = story?.shareableImages.filter(
          (s) => s.id !== props.id,
        );

        await videoCreator.storyRepository?.update(videoCreator.story!);
      } else {
        videoCreator.unsavedShareableImages = (videoCreator.unsavedShareableImages || []).filter(o => o.id !== props.id)
      }

    } catch (error) {
      console.log('Error occurred in deleting sharable image: ', error);
    } finally {
      setIsDeleteLoading(false);
      toggleDropdown(false);
    }
  };

  return (
    <Main>
      <SocialShares>
        <SocialShareStatus
          isImage
          sharedContents={props.allReferencingSharedContents}
        />
      </SocialShares>


      <Content background={background}>
        <Icons>
          <Share
            onClick={() => {
              toggleShare({
                id: props.id,
                imagefile: props.imageFile,
                quote: text,
                _allReferencingSharedContents: allReferencingSharedContents,
                _allReferencingCaptions: allReferencingCaptions!
              });
            }}
          >
            <ShareIcon strokeColor={background ? '#FFF' : '#03041a'} />
          </Share>
          {contentStudioEnableShareableImageSave && (
            <Ellipsis onClick={() => toggleDropdown(true)}>
              <EllipsisIcon fillColor={background ? '#FFF' : '#03041a'} />

              <Actions ref={buttonRef} isVisible={openDropdown}>
                <AddPhoto
                  onClick={() => {
                    toggleMedia(true);
                    toggleDropdown(false);
                  }}
                >
                  <span>Add Photo</span>
                  <div className="icon">
                    <CameraIcon />
                  </div>
                </AddPhoto>
                <SaveButton onClick={saveShareableImage}>
                  <span>Save</span>
                </SaveButton>
                <DeleteButton onClick={deleteShareableImage}>
                  <span>Delete</span>
                </DeleteButton>
              </Actions>
            </Ellipsis>
          )}
        </Icons>
        <Quote>
          <QuoteIcon>
            <QuoteStartIcon fillColor={background ? '#FFF' : '#03041a'} />
          </QuoteIcon>
          <Text
            ref={(e) => {
              if (e) {
                const computedStyle = window.getComputedStyle(e);
                const lineHeight = parseFloat(computedStyle.lineHeight);
                const lines = Math.round(e.clientHeight / lineHeight);
                if (lines > 4 && lines < 6) {
                  e.style.fontSize = '21px';
                } else if (lines >= 6) {
                  e.style.fontSize = '18px';
                }
              }
            }}
            background={background}
          >
            {text}
          </Text>
          {storyTeller && <StoryTeller>{storyTeller}</StoryTeller>}
        </Quote>
        {isSavingLoading && (
          <SpinningLoading
            customStyle={{
              top: 0,
              position: 'fixed',
              alignItems: 'center',
            }}
            text="Saving sharable image..."
          />
        )}
        {isDeleteLoading && (
          <SpinningLoading
            customStyle={{
              top: 0,
              position: 'fixed',
              alignItems: 'center',
            }}
            text="Deleting sharable image..."
          />
        )}

        {openMedia && (
          <Modal
            isOpen={true}
            onClose={() => toggleMedia(false)}
            paddingHorizontal="0"
          >
            <PhotoModal
              TopComponent={
                <PhotoModalTop
                  replaceAction={replaceAction}
                  title="social posts"
                  isSelected={!!selectedImage}
                />
              }
              otherFields={['stock']}
              onCloseSelf={() => toggleMedia(false)}
              openPrevModal={() => { }}
              selectedImageUrl={selectedImage?.url || null}
              setSelectedImage={setSelectedImage}
              from="quotecard"
            />
          </Modal>
        )}

        {sharablePost && (
          <SharableImagesModal
            sharablePost={sharablePost}
            toggleShare={toggleShare}
          />
        )}
      </Content>
      <Stats>
        <SocialStats
          type="shareableContent"
          id={props.id}
          allReferencingSharedContents={props.allReferencingSharedContents}
          layout="wide"
        />
      </Stats>
    </Main>
  );
});

export default QuoteCard;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`

const SocialShares = styled.div`
  margin-left: auto;
`

const Stats = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const Content = styled.div<{ background?: string }>`
  display: flex;
  padding: 40px 32px;
  align-items: center;
  gap: 40px;
  border-radius: 8px;
  background-color: #fff;
  flex-direction: column;
  gap: 12px;

  max-width: 386px;
  min-height: 364px;

  ${(props) =>
    props.background &&
    css`
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0) 100%
        ),
        url(${props.background});

      background-size: cover;
      background-position: center center;
    `}
`;

const Quote = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  margin-top: auto;
`;

const QuoteIcon = styled.div``;

const Text = styled.div<{ background?: string }>`
  color: ${(props) => (props.background ? '#fff' : '#030419')};
  font-size: 24px;
  font-weight: 800;
  line-height: 29.4px;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
`;

const Copy = styled.div`
  margin-left: auto;
`;

const Icons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Share = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Ellipsis = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StoryTeller = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #fff;
  width: fit-content;
  color: #03041a;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.8px;
`;

const Actions = styled(ActionsWrapper)``;

const AddPhoto = styled(ActionButton)``;

const SaveButton = styled(ActionButton)``;

const DeleteButton = styled(ActionButton)`
  &:hover {
    background-color: #ef5d6f;
  }
`;
const SectionTitle = styled.h6`
  color: #f3e9d7;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;
`;