import { Studio } from '../types.ts/general';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { videoCreator } from '../stores/VideoCreatorStore';
import { getCurrentStudio } from '../utility/general';
import VideosQuerySubscriptionComponent from './VideosQuerySubscriptionComponent';
import MyAudioQuerySubscriptionComponent from './MyAudioQuerySubscriptionComponent';

const GlobalQuerySubscriptionComponent = observer(() => {
  const { pathname } = useLocation();
  const currentStudio = getCurrentStudio(pathname);
  const generatingmyAudio =
    videoCreator.story?.myAudios?.filter(
      (o) => o.metadataStatus === 'generating' && !o.waveformUploadId,
    ) || [];

  if (!currentStudio) return null;

  return (
    <>
      {(currentStudio === Studio.creator || currentStudio === Studio.content) &&
        !!videoCreator.renderingVideoIds.length && (
          <VideosQuerySubscriptionComponent
            key={videoCreator.renderingVideoIds.join('_')}
            videoIds={videoCreator.renderingVideoIds}
            onError={(error) => {
              console.error('VideosQuerySubscriptionComponent error', error);
            }}
            onDataReceived={async (allVideos) => {
              for (const video of allVideos) {
                await videoCreator.handleVideoRenderingStatusUpdate(video);
              }
            }}
          />
        )}

      {(currentStudio === Studio.creator || currentStudio === Studio.content) &&
        !!generatingmyAudio.length && (
          <MyAudioQuerySubscriptionComponent
            key={generatingmyAudio[0].id}
            audioIds={generatingmyAudio.map((audio) => audio.id)}
            onError={(error) => {
              console.error('MyAudioQuerySubscriptionComponent error', error);
            }}
            onDataReceived={async (audios) => {
              console.log('audio processed', audios);
              await videoCreator.handleUpdatemyAudioMetadata(audios);
            }}
          />
        )}
    </>
  );
});

export default GlobalQuerySubscriptionComponent;
