import { PropertyCaption } from './PropertyCaption';
import { PropertyNumericalInput } from './PropertyNumericalInput';
import styled from 'styled-components';
import { numericalInputStyles } from '../../styles/mainStyle';
import PropertyDropdown from '../common/PropertyDropdown';
import {
  KARAOKE_TRACK_NUMBER,
  videoCreator,
} from '../../stores/VideoCreatorStore';
import { observer } from 'mobx-react-lite';

export const TextAnimationSettings = observer((props) => {
  const { animations, track } = videoCreator.textProducer.getTextConfig();
  const isKaraoke = track === KARAOKE_TRACK_NUMBER;

  const enterAnimationData = animations?.find(
    (keyframe: any) => keyframe.time === 'start' || keyframe.time === 0,
  );
  const exitAnimationData = animations?.find(
    (keyframe: any) => keyframe.time === 'end',
  );

  const getEnterAnimation = () => {
    const enterAnimation = enterAnimationData?.type ?? 'none';
    const enterAnimationDuration = enterAnimationData?.duration;
    return { animation: enterAnimation, duration: enterAnimationDuration };
  };

  const getExitAnimation = () => {
    const exitAnimation = exitAnimationData?.type ?? 'none';
    const exitAnimationDuration = exitAnimationData?.duration;
    return { animation: exitAnimation, duration: exitAnimationDuration };
  };

  const enterAnimation = getEnterAnimation().animation;
  const exitAnimation = getExitAnimation().animation;

  const enterAnimationDuration = getEnterAnimation().duration;
  // const exitAnimationDuration = getExitAnimation().duration;

  const setAnimation = async (
    time: string | number | null,
    type: any,
    duration: number | null = null,
    background_effect: string | null = null,
  ) => {
    // Remove existing animation from list
    const newAnimations =
      animations?.filter((keyframe: any) => {
        const frameTime = keyframe.time === 0 ? 'start' : keyframe.time;
        return !(!frameTime && !time) && frameTime !== time;
      }) ?? [];

    if (type !== 'none') {
      const animation: any = {
        type,
      };

      if (!!time) {
        animation.time = time;
        animation.duration = duration || 1;
      }

      if (background_effect) {
        animation.background_effect = background_effect;
      }

      // Reverse animation when used as exit animation
      if (time === 'end') {
        animation.reversed = true;
      }

      newAnimations.push(animation);
    }
    videoCreator.textProducer.modifyProperty({ animations: newAnimations });
  };

  const getInputValue = async (time: string, duration: string) => {
    let type = time === 'start' ? enterAnimation : exitAnimation;
    let itemDuration = Number(duration);

    if (isNaN(itemDuration)) itemDuration = 1;
    await setAnimation(time, type, itemDuration);
  };

  return (
    <Content>
      <Item>
        <PropertyCaption>Enter Animation</PropertyCaption>
        <PropertyDropdown
          value={enterAnimation}
          onChange={async (value) => {
            await setAnimation('start', value, enterAnimationDuration);
          }}
          defaultValue={enterAnimation}
          values={[
            {
              caption: 'Basic Caption',
              value: 'none',
            },
            ...Object.entries(TextAnimationTypes).map(([type, caption]) => ({
              caption,
              value: type,
            })),
          ]}
        />
      </Item>

      <Item>
        <PropertyCaption>Enter Animation Length (s)</PropertyCaption>
        {/* todo: rework setting current value */}
        <PropertyNumericalInput
          propertyName="start"
          defaultValue={
            animations?.find(({ time }: any) => time === 'start' || time === 0)
              ?.duration ?? '1'
          }
          unit="s"
          getInputValue={getInputValue}
          customStyles={numericalInputStyles}
        />
      </Item>

      <Item>
        <PropertyCaption>Exit Animation</PropertyCaption>

        <PropertyDropdown
          value={exitAnimation}
          onChange={async (value) => {
            await setAnimation('end', value, enterAnimationDuration);
          }}
          defaultValue={exitAnimation}
          values={[
            {
              caption: 'Basic Caption',
              value: 'none',
            },
            ...Object.entries(TextAnimationTypes).map(([type, caption]) => ({
              caption,
              value: type,
            })),
          ]}
        />
      </Item>
      <Item>
        <PropertyCaption>Exit Animation Length (s)</PropertyCaption>
        {/* todo: rework setting current value */}
        <PropertyNumericalInput
          propertyName="end"
          defaultValue={
            animations?.find(({ time }: any) => time === 'end')?.duration ?? '1'
          }
          unit="s"
          getInputValue={getInputValue}
          customStyles={numericalInputStyles}
        />
      </Item>
    </Content>
  );
});

// Each of these animations has its own options
// For reference: https://github.com/Creatomate/creatomate-node/tree/main/src/animations

const CommonAnimationTypes = {
  fade: 'Fade',
  scale: 'Scale',
  slide: 'Slide',
  // 'rotate-slide': 'Rotate Slide',
  pan: 'Pan',
  // wipe: 'Wipe',
  // 'color-wipe': 'Color Wipe',
  // 'circular-wipe': 'Circular Wipe',
  'film-roll': 'Film Roll',
  // squash: 'Squash',
  // spin: 'Spin',
  // stripe: 'Stripe',
  flip: 'Flip',
  // shake: 'Shake',
  // bounce: 'Bounce',
  // wiggle: 'Wiggle',
  shift: 'Shift',
};

const TextAnimationTypes = {
  'text-appear': 'Appear',
  // 'text-scale': 'Text Scale',
  'text-slide': 'Slide',
  'text-reveal': 'Reveal',
  'text-fly': 'Fly',
  // 'text-spin': 'Text Spin',
  // 'text-wave': 'Text Wave',
  // 'text-counter': 'Text Counter',
  'text-typewriter': 'Typewriter',
  ...CommonAnimationTypes,
};

const Section = styled.div``;
const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
`;

const Item = styled.div``;
