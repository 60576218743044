import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { KaraokeConfig } from '../../videoTranscriptionProcessor/KaraokeProducer';

type Props = {
  defaultValue?: any;
  reverse?: boolean;
  currValue?: number;
  min?: number;
  max?: number;
  ballColor?: string;
  getValue: (value: number) => void;
  ticks?: { value: number; label: string }[];
};
const SliderSelect = (props: Props) => {
  const {min = 1, max = 100, defaultValue = 1, ballColor = '#F2D093', ticks = []} = props

  const getDefaultSliderValue = () => {
    const value = props.currValue || defaultValue;
    if (props.reverse) return max - Number(value);
    return value;
  };
  const [sliderValue, setSliderValue] = useState<number>(
    getDefaultSliderValue(),
  );

  useEffect(() => {
    if(props.currValue) setSliderValue(getDefaultSliderValue())
  }, [props.currValue])

  const handleRange = (value: any) => {
    setSliderValue(value);
    value = Math.max(min, Math.min(max, value));
    if (props.reverse) value = max - Number(value);
    props.getValue(value)
  };

  return (
    <Main 
      hasTicks={!!ticks.length}>
      <Slider
        type="range"
        min={min}
        max={max}
        value={Number(sliderValue)}
        ballColor={ballColor}
        onChange={(e) => {
          handleRange(e.target.value);
        }}
        hasTicks={!!ticks.length}
      />
      { ticks.length > 0 && <TickMarks>
        {ticks.map(tick => <TickMark key={tick.value}><TheTick></TheTick><TheLabel>{tick.label}</TheLabel></TickMark>)}
      </TickMarks>}
    </Main>
  );
};

export default SliderSelect;

const Main = styled.div<{hasTicks: boolean}>`
  display: flex;
  ${props => props.hasTicks && 'flex-direction: column;'}
  align-items: center;
`;

const Slider = styled.input<{ballColor: string, hasTicks: boolean}>`
  -webkit-appearance: none;
  z-index: 1;
  width: 100%;
  height: 1px;
  background: #484848;
  border-radius: 2px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  margin-bottom: ${props => props.hasTicks ? '5px' : '0px'};
  margin-top: ${props => props.hasTicks ? '10px' : '0px'};
  align-items: center;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${props => props.ballColor};
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border: none;
    border-radius: 50%;
    background: ${props => props.ballColor};
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

const TickMarks = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  z-index: 0;
`;

const TickMark = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 17%;
  &:first-child {
    align-items: flex-start;
  }

  &:last-child {
    align-items: flex-end;
  }
`;

const TheTick = styled.div`
    width: 1px;
  height: 10px;
  background: #484848;
  opacity: 0.7;
  margin-top: -10px;
`;

const TheLabel = styled.div`
  font-size: 10px;
  color: #484848;
  margin-top: 5px;
  text-align: center;
`;