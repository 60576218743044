import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AiGeneratedContent, Music, Story } from '../../types.ts/story';
import { videoCreator } from '../../stores/VideoCreatorStore';
import MusicSongItem from './MusicSongItem';

import { AIProducerCard, SidebarOption } from '../../types.ts/general';
import AIGenerateCard from '../common/AIGenerateCard';
import SongCollection from './SongCollection';

const MUSIC_SECTION_WIDTH_PX = 300;

type Props = {
  story?: Story;
  music?: Music[];
  selectedCard?: AIProducerCard;
};
const AIProducer: FC<Props> = (props) => {
  const [currentSong, setCurrentSong] = useState('');
  const [selectedSongs, setSelectedSongs] = useState<Music['collection']>();

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const songList = selectedSongs || videoCreator.musicOptions;

  const preloadAudio = (url: string) => {
    const audio = new Audio(url);
    audio.preload = 'auto';
    audio.load();
  };

  useEffect(() => {
    if (!songList) return;
    for (const song of songList) {
      preloadAudio(song.url);
      if (!videoCreator.audioTracksData[song.url]) {
        videoCreator.loadWaveformForSource({
          source: song.url,
          name: song.title,
        });
      }
    }
  }, [songList]);

  // const maxSongsDuration =
  //   songList?.reduce((acc, song) => {
  //     return Math.max(acc, Number(song.customData.duration.split(' ')[0]));
  //   }, 0) || 100;

  const getVideoDuration = () => {
    return videoCreator.duration;
  };

  const getAIMood = () => {
    let aiContents = props.story?.aiResponse?.responses;
    let aiContentObj = aiContents?.find(
      (content) =>
        content.title === 'Music Selection' && content.response !== '',
    ) as AiGeneratedContent<'Music Selection'> | undefined;
    let mood;

    if (!aiContentObj) {
      const storyMoodData = props.story?.originalVideo?.customData?.mood;
      if (storyMoodData) {
        mood = storyMoodData?.split(',')[0]?.trim;
      }
    } else {
      mood = aiContentObj.response?.split(',')[0];
    }

    return mood || '';
  };

  const selectedGenre: string = getAIMood().trim();

  const delayAndStopLoading = (callback: () => void) => {
    setTimeout(() => {
      callback();
      setTimeout(() => {
        videoCreator.musicProducerLoading = false;
      }, 1000);
    }, 3000);
  };

  const getSortedCollection = () => {
    const music = props.music?.find(
      (m) => m.genre.toLowerCase() === selectedGenre.toLowerCase(),
    );

    let storyLength = Number(getVideoDuration());
    const sortedCollection = music?.collection?.sort(
      (a, b) => getDuration(b) - getDuration(a),
    );
    return { storyLength, sortedCollection };
  };

  const selectSongByMood = async () => {
    const { storyLength, sortedCollection } = getSortedCollection();
    if (!sortedCollection?.length) {
      videoCreator.musicOptions = [];
      setSelectedSongs([]);
      return;
    }

    let song = sortedCollection[0];
    videoCreator.musicProducerLoading = true;

    const last = sortedCollection[sortedCollection.length - 1];
    if (getDuration(last) >= storyLength) {
      delayAndStopLoading(() => {
        videoCreator.musicOptions = [last];
        setSelectedSongs([last]);
        handleAddToTrack(
          last.url,
          last.customData.songName,
          last.customData.duration,
        );
      });
      return;
    }

    if (getDuration(sortedCollection[0]) > storyLength) {
      for (let i = 1; i < sortedCollection.length; i++) {
        if (getDuration(sortedCollection[i]) < storyLength) {
          song = sortedCollection[i - 1];
          break;
        }
      }
    }

    delayAndStopLoading(() => {
      videoCreator.musicOptions = [song];
      setSelectedSongs([song]);
      handleAddToTrack(
        song.url,
        song.customData.songName,
        song.customData.duration,
      );
    });
  };

  const selectSongsByMood = async () => {
    const { storyLength, sortedCollection } = getSortedCollection();

    if (!sortedCollection?.length) {
      videoCreator.musicOptions = [];
      setSelectedSongs([]);
      return;
    }
    videoCreator.musicProducerLoading = true;
    let songs = sortedCollection.slice(0, 5);
    const last = sortedCollection[sortedCollection.length - 1];

    if (getDuration(last) >= storyLength) {
      delayAndStopLoading(() => {
        videoCreator.musicOptions = sortedCollection.slice(
          sortedCollection.length - 5,
        );
        setSelectedSongs(sortedCollection.slice(sortedCollection.length - 5));
      });
      return;
    }

    if (getDuration(sortedCollection[0]) > storyLength) {
      for (let i = 1; i < sortedCollection.length; i++) {
        if (getDuration(sortedCollection[i]) < storyLength) {
          if (i >= 5) {
            songs = sortedCollection.slice(i - 5, i);
          } else {
            const firstPartSongs = sortedCollection.slice(0, i);
            const otherPartsSongs = sortedCollection.slice(i, 5);
            songs = [...firstPartSongs, ...otherPartsSongs];
          }

          break;
        }
      }
    }

    delayAndStopLoading(() => {
      videoCreator.musicOptions = songs;
      setSelectedSongs(songs);
    });
  };

  const getDuration = (collection: Music['collection'][0]) =>
    Number(collection?.customData?.duration?.split(' ')[0]) || 0;

  const handleAddToTrack = async (
    url: string,
    songName: string,
    mediaDuration: string,
  ) => {
    const songDuration = parseFloat(mediaDuration);
    const freeTrack = videoCreator.getFreeMediaTrack('audio', songDuration, 0);

    await videoCreator.createElement({
      name: songName.replace(/\b\w/g, (match) => match.toUpperCase()),
      type: 'audio',
      source: url,
      autoplay: true,
      time: 0,
      ...(freeTrack && { track: freeTrack }),
      volume: 10,
    });
    videoCreator.sidebarOptions = SidebarOption.editing;
  };

  const renderMusicGenerateOptions = () => (
    <>
      <audio ref={audioRef} preload="auto">
        <source src="" />
      </audio>

      <AIGenerateCard handleClick={selectSongByMood} text="Generate" />
      <AIGenerateCard
        handleClick={selectSongsByMood}
        text="Identify Music Options"
      />
      {renderGeneratedOptions()}
    </>
  );

  const renderCustomDescription = () => (
    <>
      <ContentDescription>
        <p className="description">
          <span className="bolded">
            Recommended Music based on length and emotion
          </span>
        </p>
        <div className="emotions">
          <span>Emotions identified</span>
          <div className="mood">
            <MoodTag>{selectedGenre}</MoodTag>
          </div>
        </div>
      </ContentDescription>

      <HorizontalLine height="2" viewBox="0 0 300 2" fill="none">
        <path d="M0 1L300 1.00002" stroke="#333333" />
      </HorizontalLine>
    </>
  );

  const renderGeneratedOptions = () => (
    <Content>
      {songList && (
        <>
          {renderCustomDescription()}
          {songList?.length ? (
            <SongCollection onAddToTrack={handleAddToTrack}>
              {({ handleSongSelect, isSongSelected }) =>
                songList.map((song) => (
                  <MusicSongItem
                    key={song.id}
                    audioRef={audioRef}
                    song={song}
                    currentSong={currentSong}
                    setCurrentSong={setCurrentSong}
                    mood={selectedGenre}
                    onSongClick={handleSongSelect}
                    customTimelineScale={
                      (MUSIC_SECTION_WIDTH_PX /
                        parseFloat(song.customData.duration)) *
                      1
                    }
                    isHighlighted={isSongSelected(song)}
                  />
                ))
              }
            </SongCollection>
          ) : (
            <div>No matching song in the album</div>
          )}
        </>
      )}
    </Content>
  );

  return <Main>{renderMusicGenerateOptions()}</Main>;
};

export default AIProducer;

const Main = styled.div``;

const Content = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const ContentDescription = styled.div`
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  width: 320px;
  padding: 4px 10px 8px;

  gap: 10px;
  .description {
    margin: 6px 0;
    font-weight: 700;
  }

  .emotions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;

    .mood {
      display: flex;
      gap: 5px;
    }
  }
`;

const MoodTag = styled.span`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #f2d093;
  color: #f2d093;
  font-size: 10px;
  font-weight: 500;
`;

const HorizontalLine = styled.svg`
  width: 100%;
`;
