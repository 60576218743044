import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
  forwardRef,
} from 'react';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import styled, { css } from 'styled-components';
import {
  KaraokeTextBranding,
  LowerThirdTextBranding,
} from '../../types.ts/story';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { observer } from 'mobx-react-lite';
import { delay, scrollIfNotInView } from '../../utility/general';
import { Template } from '../textProcessor/TextBrandingService';

type Props = {
  type: 'basic' | 'karaoke';
  addNewTemplate: boolean;
  toggleAddNewTemplate: Dispatch<SetStateAction<boolean>>;
  setTemplateName: Dispatch<SetStateAction<string | undefined>>;
  brandingTemplates?: Template[];
  setBrandingTemplates?: Dispatch<SetStateAction<Template[]>>;
  selectedTemplate?: Template | undefined;
  setSelectedTemplate?: Dispatch<SetStateAction<Template | undefined>>;
  templateNameError: boolean;
  setTemplateNameError: Dispatch<SetStateAction<boolean>>;
  allowCustomTemplateSave?: boolean;
  hasActiveElement?: boolean;
  hasStickyBottom?: boolean;
};

const ArrowDownIcon = (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none">
    <path d="M2 4.33008L6 8.33008L10 4.33008" stroke="#F2D093" />
  </svg>
);

const TextBrandingTemplates = observer(
  forwardRef((props: Props, ref) => {
    const {
      type,
      addNewTemplate,
      toggleAddNewTemplate,
      setTemplateName,
      templateNameError,
      setTemplateNameError,
      allowCustomTemplateSave,
    } = props;
    const aspectRatio = videoCreator.currentVideo?.aspectRatio || '16:9';
    const { selectedBrandingTemplate, brandingTemplates, isLoading } =
      videoCreator.textBrandingService;
    const inputRef = useRef<HTMLInputElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [showDropdown, toggleDropdown] = useState<boolean>(false);

    useEffect(() => {
      videoCreator.textBrandingService.isTemplateModified = false;
    }, []);

    useEffect(() => {
      if (videoCreator.textBrandingService.closeActiveState) {
        props.toggleAddNewTemplate(false);
        toggleDropdown(false);
      }
    }, [videoCreator.textBrandingService.closeActiveState]);

    useEffect(() => {
      if (brandingTemplates) {
        (async () => {
          if (videoCreator.textBrandingService.isPropsChanged) {
            videoCreator.textBrandingService.selectBrandingTemplate(
              type,
              'custom',
            );

            await delay(500);
            videoCreator.textBrandingService.isPropsChanged = false;
          }
        })();
      }
    }, [
      selectedBrandingTemplate,
      videoCreator.textBrandingService.isPropsChanged,
    ]);

    useEffect(() => {
      if (showDropdown && dropdownRef.current && ref) {
        const container = (ref as React.RefObject<HTMLDivElement>)
          .current as Element;

        const stickyContainerHeight = props.hasStickyBottom ? 100 : 0;

        scrollIfNotInView(
          dropdownRef.current,
          container,
          stickyContainerHeight,
        );
      }
    }, [showDropdown]);

    useEffect(() => {
      if (addNewTemplate || allowCustomTemplateSave) {
        inputRef.current?.focus();
        inputRef.current?.scrollIntoView();
      }
    }, [allowCustomTemplateSave, addNewTemplate]);

    useEffect(() => {
      if (isLoading && type === 'karaoke') {
        contentRef.current?.scrollIntoView();
      }
    }, [isLoading]);

    useEffect(() => {
      if (allowCustomTemplateSave) return;
      videoCreator.textBrandingService.getAllBrandingTemplates(
        type,
        aspectRatio,
        props.hasActiveElement,
      );
    }, [type, allowCustomTemplateSave]);

    const contentRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(contentRef, () => {
      toggleDropdown(false);
    });

    const renderDropdownItem = (template: Template) => {
      return (
        <DropdownItem
          key={template.id as string}
          onClick={() => {
            toggleDropdown(false);
            videoCreator.textBrandingService.applyTemplate(template, type);
          }}
        >
          {template.title}
        </DropdownItem>
      );
    };

    return (
      <Main ref={contentRef}>
        <FieldName type={type}>
          {addNewTemplate || allowCustomTemplateSave
            ? 'Template Name'
            : 'Template'}
        </FieldName>
        <InputContainer
          error={templateNameError}
          onClick={() => {
            if (!addNewTemplate && !allowCustomTemplateSave) {
              toggleDropdown(!showDropdown);
            }
          }}
        >
          {addNewTemplate || allowCustomTemplateSave ? (
            <InputContent
              ref={inputRef}
              placeholder="Enter Template Name"
              onChange={(e) => {
                setTemplateName(e.target.value);
                setTemplateNameError(false);
              }}
            ></InputContent>
          ) : (
            <>
              <SelectedContent>
                {videoCreator.textBrandingService.selectedBrandingTemplate
                  ?.title || ''}
              </SelectedContent>
              {<ToggleIcon>{ArrowDownIcon}</ToggleIcon>}
            </>
          )}
        </InputContainer>

        {showDropdown && (
          <Dropdown ref={dropdownRef}>
            {videoCreator.textBrandingService.brandingTemplates?.map((t) =>
              renderDropdownItem(t),
            )}
            <AddTemplateButton
              role="button"
              onClick={() => {
                videoCreator.textBrandingService.isTemplateModified = false;
                videoCreator.textBrandingService.prepareTemplateToSave = true;
                toggleAddNewTemplate(true);
                toggleDropdown(false);
                inputRef.current?.focus();
              }}
            >
              + Add New Template
            </AddTemplateButton>
          </Dropdown>
        )}
        {templateNameError && (
          <TemplateNameError>Template name is required</TemplateNameError>
        )}
      </Main>
    );
  }),
);

export default TextBrandingTemplates;

const Main = styled.div`
  position: relative;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const InputContainer = styled.div<{ error: boolean }>`
  border: 1px solid ${(props) => (props.error ? '#BF1818' : '#484848')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  cursor: pointer;

  padding: 0px 16px;
  padding-left: 0;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
`;

const SelectedContent = styled.span`
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  color: #f3e9d7;
  padding: 0px 16px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InputContent = styled.input`
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  height: 100%;
  width: 100%;
  background-color: transparent;
  color: #f3e9d7;
  outline: 0;
  border: 0;
  padding-left: 16px;
  &::placeholder {
    color: #848484;
  }
`;
const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #484848;
  border-radius: 8px;
  background-color: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  padding: 5px 10px 0 10px;
  max-height: 200px;
  width: calc(100% - 20px);
  overflow: auto;
  position: absolute;

  cursor: pointer;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const DropdownItem = styled.span`
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  padding: 12px;
  border-bottom: 1px solid #484848;
  &:hover {
    color: #f2d093;
  }
`;

const AddTemplateButton = styled.span`
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  padding: 12px;
  font-weight: 600;
  text-decoration-line: underline;
  color: #f2d093;
  position: sticky;
  bottom: 0;
  min-height: 30px;
  display: flex;
  align-items: flex-end;
  background-color: #030419;
`;

const ToggleIcon = styled.button`
  border: 0;
  outline: 0;
  background-color: transparent;
`;

const TemplateNameError = styled.small`
  color: #bf1818;
`;

const FieldName = styled.span<{ type: 'basic' | 'karaoke' }>`
  color: ${(props) => (props.type === 'basic' ? '#848484' : '#f3e9d7')};
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
`;
