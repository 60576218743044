import { useState, useEffect, createRef, Dispatch, SetStateAction } from 'react';
import ShareModal from './ShareModal';
import { AiGeneratedContent, Artifact, Caption, ShareableImagePreviewType, ShareableImageType, SharedContent } from '../../types.ts/story';
import styled, { css } from 'styled-components';
import { getPosterUrl, getShareableImageDimension } from '../../utility/general';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import SocialShareStatus from './SocialShareStatus';
import ShareableImageView from '../common/ShareableImageView';

type Props = {
  sharablePost: {
    id: string;
    quote: string;
    imagefile?: Artifact;
    _allReferencingSharedContents?: SharedContent[];
    _allReferencingCaptions?: Caption[]
  };
  toggleShare: Dispatch<SetStateAction<ShareableImageType | null>>;
};

const SharableImagesModal = (props: Props) => {
  const imageRef = createRef<HTMLImageElement>();
  const [currDimension, setCurrDimension] = useState<string>('1:1');
  const [divWidth, setDivWidth] =
    useState(document.getElementById('modal')?.offsetWidth || 0);

  const onSelectDimension = (dimension: string) => {
    setCurrDimension(dimension);
    setDivWidth(document.getElementById('modal')?.offsetWidth || 0)
  };
  const { contentStudioEnableImageShare, enableSharing } = useFlagsCombination();


  const dimension = getShareableImageDimension(currDimension);
  const background = getPosterUrl(
    props.sharablePost.imagefile?.url || '',
    dimension.width!,
    dimension.height!,
    false,
  );

  const clip = {
    id: props.sharablePost.id,
    quote: props.sharablePost.quote,
    url: background,
    imageFile: props.sharablePost.imagefile,
    _allReferencingSharedContents: props.sharablePost._allReferencingSharedContents,
    _allReferencingCaptions: props.sharablePost._allReferencingCaptions,
  }

  return (
    <ShareModal
      onClose={() => props.toggleShare(null)}
      shareableImageToggle={props.toggleShare}
      dimensions={['16:9', '1:1', '9:16']}
      shareable={clip as ShareableImagePreviewType}
      onSelectDimension={onSelectDimension}
      hideShareButton={!contentStudioEnableImageShare}
      imageRef={imageRef}
    >
      <Main>
        <Icons>
          {enableSharing && (<SocialShareStatus sharedContents={clip?._allReferencingSharedContents} isImage={true} />)}
        </Icons>
        <ContentArea >
          <ShareableImageView
            background={background}
            width={`${dimension.width / 2}px`}
            // height={`${dimension.height / 2}px`}
            aspectRatio={currDimension.split(":").join("/")}
            imageRef={imageRef}
            quote={props.sharablePost.quote}
            divWidth={divWidth}
            setDivWidth={setDivWidth}
          />
        </ContentArea>
      </Main>
    </ShareModal>
  );
};

export default SharableImagesModal;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Icons = styled.div`
  display: flex;
  gap: 10px;
`;

const ContentArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100%;
  // height: 100%;
`;

const ContentWrapper = styled.div<{
  width: number;
  height: number;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: 8px;
  overflow: hidden;
`;

const Content = styled.div<{
  background?: string;
  width: number;
  height: number;
}>`
  display: flex;
  align-items: center;
  gap: 40px;
  background-color: #fff;
  flex-direction: column;
  gap: 12px;
  // width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;

  ${(props) =>
    props.background &&
    css`
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0) 100%
        ),
        url(${props.background});

      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
    `}
`;

const Quote = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  width: 90%;
  margin-top: auto;
  margin-bottom: 10px;
`;

const QuoteIcon = styled.div``;

const Text = styled.div<{ background?: string }>`
  color: ${(props) => (props.background ? '#fff' : '#030419')};
  font-size: 24px;
  font-weight: 800;
  line-height: 29.4px;

  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
`;

const StoryTeller = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #fff;
  width: fit-content;
  color: #03041a;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.8px;
`;
