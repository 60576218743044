// home/joe/Projects/arbor/dato-video-plugin/src/components/debug/DebugModal.tsx
import React, {useRef, useEffect} from 'react';
import Modal from '../common/Modal'
import styled from 'styled-components';

interface DebugModalProps {
  onClose: () => void;
  content: any;
  title: string;
}

const DebugModal: React.FC<DebugModalProps> = ({ content, onClose, title }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, []);


  const prettyPrintJson = (data: any) => {
    try {
      return JSON.stringify(data, null, 2);
    } catch (error) {
      return String(data);
    }
  };

  return (
    <Modal isOpen={true} closeModal={onClose}>
      <ModalWrapper ref={modalRef} tabIndex={-1}>
        <DebugHeader>
          <DebugHeading>{title}</DebugHeading>
        </DebugHeader>
        <DebugContent>
          <pre>{prettyPrintJson(content)}</pre>
        </DebugContent>
      </ModalWrapper>
    </Modal>
  );
};

const ModalWrapper = styled.div`
  background-color: #030419;
  color: #f3e9d7;
  width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #484848;
  display: flex;
  flex-direction: column;
`;

const DebugHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const DebugHeading = styled.h2`
  font-size: 18px;
  color: #f2d093;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  color: #f2d093;
  cursor: pointer;
  font-size: 20px;
  &:hover {
    color: #dfb615;
  }
`;

const DebugContent = styled.div`
  white-space: pre-wrap;
  background-color: #1e1e1e;
  color: #c5c5c5;
  padding: 16px;
  border-radius: 8px;
  font-size: 12px;
  overflow: auto;
  text-align: left;
`;

export default DebugModal;
