import { runInAction } from 'mobx';
import { Subtitles } from '../types.ts/story';
import { tokenize } from '../utility/tokenize';
import {
  capitalize,
  decapitalize,
  getClosestElementIndexToLeftByFilter,
  getClosestElementIndexToRightByFilter,
  getClosestTextIndexToLeft,
  getClosestTextIndexToRight,
  isSentenceEnd,
  TranscriptChange,
  TranscriptElement,
} from './utils';

export default class SubtitlesProcessor {
  private subtitleElements?: TranscriptElement[];
  onSubtitleElementsChange: (elements?: TranscriptElement[]) => void = () => {};

  constructor(
    onSubtitleElementsChange: (elements?: TranscriptElement[]) => void,
  ) {
    this.onSubtitleElementsChange = onSubtitleElementsChange;
  }

  setSubtitleElements(elements?: TranscriptElement[]) {
    this.subtitleElements = elements;
    console.trace('setSubtitleElements', elements);
    this.onSubtitleElementsChange(elements);
  }

  getSubtitleElements() {
    return this.subtitleElements;
  }

  convertToSubtitleElements(subtitles: Subtitles) {
    let elements: TranscriptElement[] = [];
    for (const line of subtitles.lines) {
      const tokens = tokenize(line.translated) as TranscriptElement[];
      if (tokens.length === 0) continue;
      tokens.at(-1)!.karaoke_break = true;
      let ts = line.start;
      const numberOfWords = tokens.filter((t) => t.type === 'text').length;
      if (numberOfWords === 0) {
        elements = elements.concat(tokens);
      } else {
        elements = elements.concat(
          tokens.map((el) => {
            if (el.type === 'text') {
              const wordDuration = (line.end - line.start) / numberOfWords;
              const newEl = {
                ...el,
                ts,
                end_ts: Math.min(ts + wordDuration, line.end),
              };
              if (line.karaoke_break_start_ts_diff && ts === line.start) {
                newEl.karaoke_break_start_ts_diff =
                  line.karaoke_break_start_ts_diff;
              }
              if (
                line.karaoke_break_end_ts_diff &&
                Math.abs(newEl.end_ts - line.end) < 0.001
              ) {
                newEl.karaoke_break_end_ts_diff =
                  line.karaoke_break_end_ts_diff;
              }
              ts += wordDuration;
              return newEl;
            }
            return el;
          }),
        );
      }
    }
    return elements;
  }

  getSubtitleLines(): Subtitles {
    if (!this.subtitleElements) {
      return { lines: [] };
    }
    const lines: Subtitles['lines'] = [];
    let line: Subtitles['lines'][0] = {
      text: '',
      start: -1,
      end: -1,
      translated: '',
    };
    for (const element of this.subtitleElements) {
      line.translated += element.value;
      if (line.start < 0 && element.type === 'text' && element.ts >= 0) {
        line.start = element.ts;
      }
      if (element.type === 'text' && element.end_ts) {
        line.end = element.end_ts;
      }
      if (element.karaoke_break_start_ts_diff) {
        line.karaoke_break_start_ts_diff = element.karaoke_break_start_ts_diff;
      }
      if (element.karaoke_break_end_ts_diff) {
        line.karaoke_break_end_ts_diff = element.karaoke_break_end_ts_diff;
      }
      if (element.karaoke_break) {
        lines.push(line);
        line = {
          text: '',
          start: -1,
          end: -1,
          translated: '',
        };
      }
    }
    if (line.start > 0) {
      lines.push(line);
    }
    return { lines };
  }

  addKaraokeBreaks(positions: number[]) {
    runInAction(() => {
      for (const position of positions) {
        this.subtitleElements![position].karaoke_break = true;
      }
    });
    this.onSubtitleElementsChange(this.subtitleElements!);
  }

  removeKaraokeBreak(position: number) {
    runInAction(() => {
      delete this.subtitleElements![position].karaoke_break;
    });
    this.onSubtitleElementsChange(this.subtitleElements!);
  }

  removeAllKaraokeBreaks() {
    runInAction(() => {
      this.subtitleElements!.forEach((element) => {
        delete element.karaoke_break;
      });
    });
    this.onSubtitleElementsChange(this.subtitleElements!);
  }

  applyPlacementToElement(
    index: number,
    {
      karaoke_break_end_ts_diff,
      karaoke_break_start_ts_diff,
    }: {
      karaoke_break_end_ts_diff?: number;
      karaoke_break_start_ts_diff?: number;
    },
  ) {
    const element = this.subtitleElements![index];
    element.karaoke_break_end_ts_diff = karaoke_break_end_ts_diff;
    element.karaoke_break_start_ts_diff = karaoke_break_start_ts_diff;
    this.onSubtitleElementsChange(this.subtitleElements!);
  }

  addPunctuation(
    code: 'Comma' | 'Period' | 'Space' | 'Enter',
    position: number,
  ) {
    const punctMap = {
      Comma: ',',
      Period: '.',
      Space: ' ',
      Enter: '\n',
    } as Record<typeof code, ' ' | ',' | '.' | '\n'>;
    this.subtitleElements!.splice(position, 0, {
      type: 'punct',
      value: punctMap[code],
    } as TranscriptElement);
    this.handleCapitalization({
      type: 'insert_punct',
      index: position,
      value: punctMap[code],
      count: 1,
    } as TranscriptChange);
    this.onSubtitleElementsChange(this.subtitleElements!);
  }

  handleCapitalization(change: TranscriptChange) {
    let handleCapitalization = ![
      'remove_all_karaoke_breaks',
      'replace',
      'add_karaoke_break',
      'change_karaoke_end_break_time',
      'change_karaoke_start_break_time',
    ].includes(change.type);

    if (change.type === 'replace') {
      handleCapitalization =
        isSentenceEnd(change.newValue!) !== isSentenceEnd(change.oldValue!);
    }
    if (change.type === 'insert_punct' && change.value === '\n') {
      handleCapitalization = false;
    }

    if (handleCapitalization && typeof change.index === 'number') {
      this.subtitleElements = this.fixCapitalization({
        ...change,
        index: change.index as number,
      });
    }
  }

  moveSubtitles(fromTs: number, toTs: number, shiftTs: number) {
    debugger;
    if (!this.subtitleElements?.length) {
      return;
    }
    let subtitleElements = this.subtitleElements!;
    const firstSubtitleElement = subtitleElements.findIndex(
      (el) => el.ts != null && el.ts > fromTs - 0.001,
    );
    //@ts-ignore
    const lastSubtitleElement = subtitleElements.findLastIndex(
      (el: TranscriptElement) => el.end_ts != null && el.end_ts < toTs + 0.001,
    );

    const timeChange = shiftTs;
    for (let i = firstSubtitleElement; i <= lastSubtitleElement; i++) {
      if (subtitleElements[i].type === 'text') {
        subtitleElements[i].ts! += timeChange;
        subtitleElements[i].end_ts! += timeChange;
      }
    }
    this.onSubtitleElementsChange(this.subtitleElements!);
  }

  restoreMutedTextElements(fromElement: number, toElement: number) {
    debugger;
    const elements = this.subtitleElements!;
    const elementsCount = toElement - fromElement + 1;
    for (let i = 0; i < elementsCount; i++) {
      if (elements[fromElement + i].state === 'muted') {
        delete elements[fromElement + i].state;
        if (elements[i].muted_by_hideFillers) {
          delete elements[i].muted_by_hideFillers;
        }
      }
    }
    this.handleCapitalization({
      type: 'restore_mute',
      index: fromElement,
      count: elementsCount,
    } as TranscriptChange);
    this.onSubtitleElementsChange(this.subtitleElements!);
  }

  hideKaraoke(boundaries: { startIndex: number; endIndex: number }) {
    const elementsCount = boundaries.endIndex - boundaries.startIndex + 1;
    for (let i = 0; i < elementsCount; i++) {
      this.subtitleElements![boundaries.startIndex + i].state = 'muted';
    }
    this.handleCapitalization({
      type: 'mute',
      index: boundaries.startIndex,
      count: elementsCount,
    } as TranscriptChange);
    this.onSubtitleElementsChange(this.subtitleElements!);
  }

  removeSubtitleElements(startIndex: number, endIndex: number) {
    this.subtitleElements!.splice(startIndex, endIndex - startIndex);
    this.handleCapitalization({
      type: 'mute',
      index: startIndex,
      count: endIndex - startIndex + 1,
    } as TranscriptChange);
    this.onSubtitleElementsChange(this.subtitleElements!);
  }

  replaceSubtitlesElement(
    startIndex: number,
    endIndex: number,
    newValue: string,
  ) {
    let elements = this.subtitleElements!;
    const elementsCount = Math.max(endIndex - startIndex + 1, 1);
    const newTokens = tokenize(newValue) as TranscriptElement[];
    const prevKaraokeBreakIndex = getClosestElementIndexToLeftByFilter(
      startIndex - 1,
      elements,
      (el) => !!el.karaoke_break,
    );
    const nextKaraokeBreakIndex = getClosestElementIndexToRightByFilter(
      endIndex,
      elements,
      (el) => !!el.karaoke_break,
    );
    const lineStartIdx = getClosestTextIndexToRight(
      prevKaraokeBreakIndex + 1,
      elements,
    );
    const lineEndIdx = getClosestTextIndexToLeft(
      nextKaraokeBreakIndex > -1 ? nextKaraokeBreakIndex : elements.length - 1,
      elements,
    );
    let ts = elements[lineStartIdx].ts!;
    const ts_end = elements[lineEndIdx].end_ts!;
    let newLineElements = elements.slice(
      prevKaraokeBreakIndex + 1,
      nextKaraokeBreakIndex > -1 ? nextKaraokeBreakIndex + 1 : elements.length,
    );
    if (endIndex === nextKaraokeBreakIndex) {
      newTokens.at(-1)!.karaoke_break = true;
    }

    const deletedElements = newLineElements.splice(
      startIndex - (prevKaraokeBreakIndex + 1),
      elementsCount,
      ...newTokens,
    );

    const numberOfWords = newLineElements.filter(
      (t) => t.type === 'text',
    ).length;
    if (numberOfWords > 0) {
      const wordDuration = (ts_end - ts) / numberOfWords;
      newLineElements = newLineElements.map((el) => {
        if (el.type === 'text') {
          const newEl = {
            ...el,
            ts,
            end_ts: Math.min(ts + wordDuration, ts_end),
          };
          ts += wordDuration;
          return newEl;
        }
        return el;
      });
    }

    this.subtitleElements?.splice(
      prevKaraokeBreakIndex + 1,
      nextKaraokeBreakIndex > -1
        ? nextKaraokeBreakIndex - prevKaraokeBreakIndex
        : elements.length - prevKaraokeBreakIndex - 1,
      ...newLineElements,
    );

    this.handleCapitalization({
      type: 'replace',
      index: startIndex,
      oldValue: deletedElements.map((el) => el.value || '').join(''),
      newValue: newValue,
      count: newTokens.length,
    } as TranscriptChange);

    this.onSubtitleElementsChange(this.subtitleElements!);
  }

  private fixCapitalization(change: TranscriptChange & { index: number }) {
    const elements = this.subtitleElements!;
    // for remove, cut, mute
    // check closest non-space element before change.index
    // if it ends with Period, Exclamation mark or Question mark, capitalize first non removed word after it;
    // if it doesn't end with Period, Exclamation mark or Question mark, de-capitalize first non removed word after it;

    const visibleElementsFilter = (el: TranscriptElement) =>
      el.state !== 'removed' &&
      el.state !== 'cut' &&
      el.state !== 'muted' &&
      el.value !== ' ' &&
      el.value !== '';

    if (['remove', 'mute', 'restore_mute'].includes(change.type)) {
      // debugger;
      const closestLeftIndex = getClosestElementIndexToLeftByFilter(
        change.index - 1,
        elements,
        visibleElementsFilter,
      );
      const closestLeft = elements.at(closestLeftIndex);

      const nextElementIndex = getClosestElementIndexToRightByFilter(
        change.index,
        elements,
        visibleElementsFilter,
      );

      const nextElement = elements.at(nextElementIndex);

      if (!closestLeft || isSentenceEnd(closestLeft.value)) {
        if (nextElement?.type === 'text' && nextElement?.value) {
          nextElement.value = capitalize(nextElement.value);
        }
      } else if (
        closestLeft &&
        nextElement?.type === 'text' &&
        nextElement?.value &&
        nextElement.value !== 'I' &&
        !nextElement.value.startsWith("I'") &&
        !nextElement.value.startsWith('I ')
      ) {
        nextElement.value = decapitalize(nextElement.value);
      }
    }

    if (['restore_mute', 'insert_punct', 'replace'].includes(change.type)) {
      // debugger;
      const closestLeftIndex = getClosestElementIndexToLeftByFilter(
        change.index + change.count - 1,
        elements,
        visibleElementsFilter,
      );
      const closestLeft = elements.at(closestLeftIndex);

      const nextElementIndex = getClosestElementIndexToRightByFilter(
        change.index + change.count,
        elements,
        visibleElementsFilter,
      );

      const nextElement = elements.at(nextElementIndex);

      if (!closestLeft || closestLeft.value?.match(/[\.\!\?]\s*$/)) {
        if (nextElement?.type === 'text' && nextElement?.value) {
          nextElement.value = capitalize(nextElement.value);
        }
      } else if (
        closestLeft &&
        nextElement?.value &&
        nextElement.value !== 'I' &&
        !nextElement.value.startsWith("I'") &&
        !nextElement.value.startsWith('I ')
      ) {
        nextElement.value = decapitalize(nextElement.value);
      }
    }

    return elements;
  }
}
