// this file contains the default values for the AI producer

// Path: src/utility/ai_producer_defaults.ts
export const defaultSystemMessage =
  'You are a story condenser. You get input as a story and you remove words to shorten the story. You cannot change the order of any words. You cannot add any words. All you can do is remove words from the original story. The new story should be coherent and make sense. It can be a specific part of the original that is interesting or appealing to a listener.  The user will give you the length of time the original story takes to tell and the target time, so you know about how much story to remove. The output is the input text but with strikethroughs through every word that is removed. Pay close attention to how many words the user wants their story to be. Stay within 10% of that number of words.';
export const defaultUserPrompt =
  'This story is {{originalVideoWords}} words long. I want it to contain approximately {{newVideoWords}} words. Use only exact quotes from this story including all conjugation, capitalization and punctuation. Nothing can change. All you can do is remove words. Do not reorder or paraphrase at all.';
export const defaultSampleTranscript =
  "In 2003, I went on a trip to Disney with my best friend and you know, his family we went to, it was the Disney wide world of sports complex. 'cause that's where the Braves did all the spring training. And we went to a game, I think it was the Cardinals, and it was super cool. We're able to just kind of walk around the complex and it, you know, we're 20 feet away from the bullpen, you know, you're watching the players walk out to the, to the parking lot. And one of my favorite stories about that was Greg Maddox, now Hall of Fame pitcher, Greg Maddox. He was just wearing like a Hawaiian shirt and driving what looked like an old used Ford Explorer. You know, just a regular guy. So it was very cool to just see that and be like, that's one of the greatest players in the game right now. And he's just a normal dude. So, you know, as a 17 year old seeing that and thinking he's not driving some fancy luxury car, there were, there were players driving luxury cars to, to spring training. Absolutely. But he didn't seem like he wanted to be bothered with that at all. It was just a regular explorer.";
export const defaultSampleReturnTranscript =
  "In 2003, I went on a trip to Disney with my best friend and his family. We went to the Disney wide world of sports complex. That's where the Braves did spring training. We went to a game, I think it was the Cardinals. We're able to walk around the complex, we're 20 feet away from the bullpen, watching the players walk out to the parking lot. One of my favorite stories was Greg Maddox, Hall of Fame pitcher. He was wearing a Hawaiian shirt and driving an old used Ford Explorer. Just a regular guy. It was cool to see that, that's one of the greatest players in the game. He's just a normal dude. As a 17 year old seeing that, thinking he's not driving some luxury car, there were players driving luxury cars to spring training. But he didn't seem like he wanted to be bothered with that. It was just a regular explorer.";
export const defaultVideoLength = '60';

// version 2 of ai producer

export const defaultUserPrompt2 =
  'The original transcript is {{originalVideoWords}} words long. I want the new story to contain {{newVideoWords}} words. Rewrite the shortened story to be {{newVideoWords}} long.';

export const defaultSystemMessage2 = `You are an amazing documentary film producer. You craft compelling stories from raw, first person videos. You are given a story and a video. You need to create a story from the video.`;

export const storyQuestions2 = `
  Here are 10 questions that must be answered using the transcript of someone telling a story:

  1. Purpose: What is the purpose of the story? The story should have a clear purpose. Whether it’s to educate, persuade, inspire, or entertain, the audience needs to understand the reason behind the story. This purpose should be apparent throughout the documentary.
  2. Perspective: What interesting perspective does this story illuminate? A personal story should give us an intimate look at someone's life. The person should share their perspective and emotions. These insights make the story unique and engaging. 
  3. Conflict or Struggle: What conflict/struggle does this story have? This is a crucial component that creates the plot. It's this conflict, challenge, or struggle that the person has to deal with that keeps the audience engaged and invested in the story and its outcome.
  4. Resolution: What is the resolution to the conflict/struggle in this story? The resolution is the climax of the story where the conflict or struggle is resolved. This gives the narrative closure, though it doesn't necessarily have to be a "happy ending".
  5. Authenticity: How is this story authentic? A good personal documentary should feel genuine and sincere. The protagonist's actions and reactions should feel authentic and not staged or forced.
  6. Character Development: What is the protagonist or main character of the story? The protagonist should change or grow throughout the story. This character development can be pivotal in making the story compelling. A protagonist/character need not be a person, it could be any story subject matter that is changing over the course of the story (e.g. a company, a product, a place, etc.).
  7. Engaging Narration: How is the story engaging? A personal story is narrated by the person, it should be delivered in an engaging and compelling manner to keep the audience involved and interested.
  8. Theme: What is the theme of the story? Every story has a theme, the underlying message, or big idea. This theme should be apparent throughout the documentary and should be clear to the audience.
  9. Emotional Connection: Why does the audience connect to this story? The audience should feel an emotional connection with the person telling the story. This will make the story more impactful and memorable for the audience.
  10. Structure: What is the beginning, middle and end of the story? The personal story needs to be carefully structured with a clear beginning, middle, and end. This structure will help the audience follow the story and understand the narrative arc. The beginning should introduce the protagonist and the conflict or struggle. The middle should show the protagonist dealing with the conflict or struggle. The end should show the resolution of the conflict or struggle. The story should flow smoothly from beginning to end.

  Do you understand the 10 questions?
`;

export const aiQuestionUnderstanding2 = `Yes, I understand the 10 questions. They are all vital aspects that need to be considered when producing a documentary. They help in creating a compelling narrative that not only tells a story but also engages and connects with the audience on an emotional level.`;

export const giveAITranscript2 = `Here is the transcript of the story: {{transcript}} \n\n Do you understand the story?`;

export const askForAnswers2 = `What are the 10 answers for this story?`;

export const askForOutline2 = `With those 10 answers in mind, write an outline for the story, using proper story structure, while preserving all the answers to the 10 questions.`;

export const askForStory2 = `Now write the story, using the outline and the answers to the 10 questions. Using only phrases found in the original text of the story. Remember, this is a story transcribed from a video, so no new words can be introduced. And the longer the span of words from the original text, the smoother the final video will be.`;

export const askForSimpleParagraph2 = `Can you rewrite the story, as a single paragraph only using phrases and sentences found in the original text transcript?`;

export const getOriginalSententences2 = `For each sentence in the condensed story replace it with a sentence in the original transcript that most closely matches it.`;

export const removeUnnecessaryWords2 = `For the new story, remove any words that are not necessary to the story.`;

// v3 of ai producer
export const defaultUserPrompt3 = `Please take the following transcript and provide a {{newVideoWords}} words edited version of it. You are not allowed to change words, or put words out of order. Focus on the emotional story and away from the technical story.

Transcript
{{transcript}}`;

export const defaultSystemMessage3 = `You are a world class story editor. You can take text and condense it. You are not allowed to change words, or put words out of order. Please take the transcript and provide a {{newVideoWords}} words edited version of it. Focus on the emotional story and away from the technical story`;

export const getOriginalSententences3 = `Please tell this story only using sentences from the original transcript.`;

export const SUPPORTED_ASPECT_RATIO = ['16:9', '9:16', '1:1'];


export const getOriginalSententences4 = `What sentence in the original transcript most closely matches this sentence {{sentence}}? Just give the original sentence without additional formatting.`;