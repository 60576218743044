import React, { KeyboardEvent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { RgbaColorPicker, RgbaColor } from 'react-colorful';
import CheckIcon from '../../svgs/CheckIcon';
import DeleteIcon from '../../svgs/DeleteIcon';
import { KaraokeConfig } from '../../videoTranscriptionProcessor/KaraokeProducer';
import { videoCreator } from '../../stores/VideoCreatorStore';

type Props = {
  pos: DOMRect;
  action?: (propertyMap: Partial<KaraokeConfig>) => void;
  propertyName?: string;
  defaultValue?: string | null;
  getValue: (color: string | null) => void;
  onClose: () => void;
  solidDefaultAlpha?: boolean;
};

enum ColorTabs {
  preset = 'Presets',
  custom = 'Custom',
}

const black = [
  'rgba(0, 0, 0, 1)',
  'rgba(82, 82, 82, 1)',
  'rgba(150, 150, 150, 1)',
  'rgba(217, 217, 217, 1)',
  'rgba(255, 255, 255, 1)',
];

const red = [
  'rgba(183, 28, 28, 1)',
  'rgba(211, 47, 47, 1)',
  'rgba(244, 67, 54, 1)',
  'rgba(229, 115, 115, 1)',
  'rgba(255, 205, 210, 1)',
];

const yellow = [
  'rgba(245, 127, 23, 1)',
  'rgba(251, 192, 45, 1)',
  'rgba(255, 235, 59, 1)',
  'rgba(255, 241, 118, 1)',
  'rgba(255, 249, 196, 1)',
];

const green = [
  'rgba(25, 77, 51, 1)',
  'rgba(56, 142, 60, 1)',
  'rgba(76, 175, 80, 1)',
  'rgba(129, 199, 132, 1)',
  'rgba(200, 230, 201, 1)',
];

const blue = [
  'rgba(13, 71, 161, 1)',
  'rgba(25, 118, 210, 1)',
  'rgba(33, 150, 243, 1)',
  'rgba(100, 181, 246, 1)',
  'rgba(187, 222, 251, 1)',
];

const crimson = [
  'rgba(136, 14, 79, 1)',
  'rgba(194, 24, 91, 1)',
  'rgba(233, 30, 99, 1)',
  'rgba(240, 98, 146, 1)',
  'rgba(248, 187, 208, 1)',
];

const purple = [
  'rgba(74, 20, 140, 1)',
  'rgba(123, 31, 162, 1)',
  'rgba(156, 39, 176, 1)',
  'rgba(186, 104, 200, 1)',
  'rgba(225, 190, 231, 1)',
];

const DefaultColors = {
  white: 'rgba(255, 255, 255, 1)',
  black: 'rgba(0, 0, 0, 1)',
  red: 'rgba(255, 0, 0, 1)',
  green: 'rgba(0, 255, 0, 1)',
  orange: 'rgba(255, 165, 0, 1)',
  blue: 'rgba(0, 0, 255, 1)',
  yellow: 'rgba(255, 255, 0, 1)',
};

function hexToRgba(hex: string, alpha?: number) {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
  } else if (hex.length === 9) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
    alpha = parseInt(hex.slice(7, 9), 16) / 255;

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 3,
      minimumFractionDigits: 0,
    });
    let a = formatter.format(alpha);

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  } else {
    console.log('Invalid hex color code.');
    return;
  }
  alpha = typeof alpha !== 'undefined' ? alpha : 1;
  alpha = Math.min(Math.max(0, alpha), 1);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

function stringToRgba(color: string) {
  const [r, g, b, a] = videoCreator.textBrandingService
    .splitRgbaString(color)
    .map(Number);
  return {
    r,
    g,
    b,
    a: a * 100,
  };
}

function getDefaultRgba(value: string | undefined | null) {
  if (!value) return '';
  if (!value.toLowerCase().includes('rgb')) {
    return DefaultColors[value as keyof typeof DefaultColors] || '';
  }
  return value.toLowerCase();
}

function getDefaultAlpha(rgba: string | null | undefined) {
  if (!rgba) return '1';
  const rgbaValues = rgba
    .substring(5, rgba.length - 1)
    .split(',')
    .map((value) => value.trim());

  if (rgbaValues.length === 4) {
    const alpha = rgbaValues[3];
    if (!alpha || alpha === 'NaN') return '1';
    return alpha;
  }
}

function rgbaToString(rgba: RgbaColor) {
  return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a / 100})`;
}

const ColorPicker = (props: Props) => {
  const [color, setColor] = useState<string>(
    getDefaultRgba(props.defaultValue),
  );

  const [defaultAlpha] = useState(
    props.solidDefaultAlpha ? '1' : getDefaultAlpha(props.defaultValue),
  );

  const [rgbaColor, setRgbaColor] = useState<RgbaColor>(
    color
      ? stringToRgba(color)
      : {
          r: 123,
          g: 64,
          b: 64,
          a: 100,
        },
  );
  const [selectedTab, setSelectedTab] = useState<ColorTabs>(ColorTabs.preset);
  const [editableColor, setEditableColor] = useState<{
    isValid: boolean;
    color: string;
  }>({
    isValid: true,
    color: '#000000',
  });
  useEffect(() => {
    if (color) {
      setEditableColor({
        isValid: true,
        color: videoCreator.textBrandingService.rgbaToHex(color).slice(0, 7),
      });
      setRgbaColor(stringToRgba(color));
    }
  }, []);

  const getLeft = () => {
    if (props.pos.left + 319 < window.innerWidth) {
      return props.pos.left;
    }
    return window.innerWidth - 340;
  };

  const getVerticalPos = () => {
    if (props.pos.bottom + 370 < window.innerHeight) {
      return { top: props.pos.top + 5 + props.pos.height };
    }
    return { top: props.pos.top - 340 };
  };

  const isMatchingPreset = (preset: string) => {
    const rgbaPreset = stringToRgba(preset);
    return (
      color === preset ||
      (rgbaColor.r === rgbaPreset.r &&
        rgbaColor.g === rgbaPreset.g &&
        rgbaColor.b === rgbaPreset.b)
    );
  };

  return (
    <>
      <Main
        onClick={(e) => {
          console.log(e.currentTarget.getBoundingClientRect());
        }}
        pos={props.pos}
        left={getLeft()}
        vPos={getVerticalPos()}
      >
        <Tabs>
          {Object.values(ColorTabs).map((t) => (
            <Tab
              onClick={() => setSelectedTab(t)}
              isSelected={selectedTab === t}
              key={t}
            >
              {t}
            </Tab>
          ))}
        </Tabs>

        <PickerContent>
          {selectedTab === ColorTabs.custom && (
            <CustomPicker>
              <RgbaColorPicker
                color={rgbaColor}
                onChange={(e) => {
                  setRgbaColor(e);
                  const _color = `rgba(${e.r}, ${e.g}, ${e.b}, ${e.a / 100})`;
                  setEditableColor({
                    isValid: true,
                    color: videoCreator.textBrandingService
                      .rgbaToHex(_color)
                      .slice(0, 7),
                  });
                  setColor(_color);
                  props.getValue(_color);
                }}
              />
            </CustomPicker>
          )}

          {selectedTab === ColorTabs.preset && (
            <Preset>
              <PresetPicker>
                {[black, red, yellow, green, blue, crimson, purple].map(
                  (column) => (
                    <PresentColumn>
                      {column.map((c) => {
                        return (
                          <PresetColor
                            onClick={() => {
                              const _color =
                                videoCreator.textBrandingService.addAlphaToRgba(
                                  c,
                                  defaultAlpha,
                                );

                              setEditableColor({
                                isValid: true,
                                color: videoCreator.textBrandingService
                                  .rgbaToHex(_color)
                                  .slice(0, 7),
                              });

                              setColor(c);
                              setRgbaColor(stringToRgba(_color));
                              props.getValue(_color);
                            }}
                            color={c}
                          >
                            {isMatchingPreset(c) && (
                              <CheckIcon height="16" width="16" />
                            )}
                          </PresetColor>
                        );
                      })}
                    </PresentColumn>
                  ),
                )}
              </PresetPicker>
            </Preset>
          )}
          <BottomPane>
            <Remove
              onClick={() => {
                setEditableColor({ isValid: false, color: '' });
                setColor('');
                props.getValue(null);
              }}
            >
              <DeleteIcon height="20px" width="20px" />
            </Remove>

            <EditableContainer>
              <PresetColor
                color={rgbaToString(rgbaColor)}
                rgbaColor={rgbaColor}
              />
              <EditableInput
                maxLength={7}
                onChange={(e) => {
                  const newColor = hexToRgba(e.target.value);
                  if (newColor) {
                    const _color =
                      videoCreator.textBrandingService.addAlphaToRgba(
                        newColor,
                        defaultAlpha,
                      );
                    setColor(_color);
                    props.getValue(_color);

                    setEditableColor({
                      isValid: true,
                      color: e.target.value.slice(0, 7),
                    });
                    setRgbaColor(stringToRgba(_color));
                  } else {
                    setColor('');
                    setEditableColor({
                      isValid: false,
                      color: e.target.value.slice(0, 7),
                    });
                  }
                }}
                value={editableColor.color}
              />
            </EditableContainer>
          </BottomPane>
        </PickerContent>
      </Main>
    </>
  );
};

export default ColorPicker;

const Main = styled.div<{
  pos: DOMRect;
  left: number;
  vPos: { top?: number; bottom?: number };
}>`
  background-color: #030419;
  z-index: 999;
  border-radius: 8px;
  width: 294px;
  border-radius: 8px;
  border: 1px solid #484848;
  position: fixed;
  top: ${(props) => props.vPos.top && `${props.vPos.top}px`};
  left: ${(props) => props.left}px;
  z-index: 9999999;
  padding: 12px;
  padding-top: 16px;

  ${(props) =>
    props.vPos.bottom &&
    css`
      bottom: ${props.vPos.bottom};
    `}

  ${(props) =>
    props.vPos.top &&
    css`
      top: ${props.vPos.bottom};
    `}
`;

const CustomPicker = styled.div`
  & .react-colorful {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  & .react-colorful__saturation {
    border-radius: 8px;
    height: 120px;
    border: 1px solid #484848;
  }
  & .react-colorful__saturation-pointer {
    width: 16px;
    height: 16px;
    color: #f3e9d7;
    background-color: #f3e9d7;
  }
  & .react-colorful__hue {
    border-radius: 20px;
    height: 16px;
  }
  & .react-colorful__hue-pointer {
    width: 8px;
    height: 8px;
    color: #f3e9d7;
    background-color: #f3e9d7;
    box-shadow: 8px 8px 16px 0px #00000066;
  }
  & .react-colorful__alpha {
    width: 12px;
    height: inherit;
    border-radius: 0;
    display: none;
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const Tab = styled.div<{ isSelected: boolean }>`
  color: ${(props) => (props.isSelected ? '#F2D093' : '#F3E9D7')};
  border-bottom: 2px solid
    ${(props) => (props.isSelected ? '#F2D093' : '#F3E9D7')};
  padding-bottom: 5px;
  width: 50%;
  cursor: pointer;
`;

const PickerContent = styled.div`
  width: 100%;
  margin-top: 10px;
`;
const Preset = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PresetPicker = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
`;

const PresetColor = styled.span<{ color: string; rgbaColor?: RgbaColor }>`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: ${(props) => props.color};

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ rgbaColor: c }) =>
    c &&
    css`
      border: ${c.r === 0 && c.b === 0 && c.g === 0 && '1px solid #484848'};
    `}

  ${({ color, rgbaColor }) =>
    color &&
    !rgbaColor &&
    css`
      border: ${color === 'rgba(0, 0, 0, 1)' && '1px solid #484848'};
    `}
`;

const PresentColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Remove = styled.div`
  border: 1px solid #484848;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BottomPane = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 12px;
`;

const EditableContainer = styled.div`
  display: flex;
  height: 36px;
  flex: 1;
  align-items: center;
  padding-left: 4px;
  border-radius: 8px;
  border: 1px solid #484848;
`;

const EditableInput = styled.input`
  background-color: transparent;
  color: #f3e9d7;
  text-transform: uppercase;
  outline: 0;
  border: 0;
  width: 36px;
  margin-left: 5px;
  flex: 1;
`;

const Check = styled.div<{ isChecked?: boolean }>`
  border-radius: 80px;
  border: 1px solid ${(props) => (props.isChecked ? '#4AD067' : '#484848')};
  background: ${(props) =>
    props.isChecked ? '#4AD067' : 'rgba(3, 4, 25, 0.4)'};
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MakeDefaultContainer = styled.div<{
  isChecked?: boolean;
  isDisabled: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    color: ${(props) => (props.isChecked ? '#F2D093' : '#f3e9d7')};
    font-size: 14px;
    font-family: Inter;
    font-weight: 500;
    line-height: 21px;
  }
  margin-top: 10px;
  ${(props) =>
    props.isDisabled &&
    css`
      &:hover .no-showcase {
        display: flex;
      }
    `}
`;

const NoShowcaseMessage = styled.div`
  position: absolute;
  color: #f3e9d7;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  background: #484848;

  display: none;
  width: 258px;
  height: 42px;
  padding: 2px 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
`;
