type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  fillColor?: string;
};
const CheckIcon = (props: Props) => {
  const {
    width = '6',
    height = '6',
    viewBox = '0 0 6 6',
    fillColor = '#F3E9D7',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M5.94779 1.37923L2.20569 5.12132C2.17254 5.15475 2.12743 5.17353 2.08036 5.17353C2.0333 5.17353 1.98818 5.15475 1.95504 5.12132L0.0522129 3.21849C0.0187953 3.18538 0 3.14026 0 3.09317C0 3.04611 0.0187953 3.00099 0.0522129 2.96784L0.299333 2.72072C0.332475 2.68733 0.377592 2.66851 0.424658 2.66851C0.471724 2.66851 0.516841 2.68733 0.549983 2.72072L2.0786 4.24934L5.45002 0.877929C5.51985 0.810222 5.63084 0.810222 5.70067 0.877929L5.94779 1.12858C5.98122 1.16172 6 1.20684 6 1.2539C6 1.30097 5.98122 1.34608 5.94779 1.37923Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default CheckIcon;
