import { saveAiFlowLogs } from './AIFlowLoader';
import { AIFlowStep } from './AIFlowStep';

export class AIFlow {
  title: string;
  steps: AIFlowStep[];
  currentStepIndex: number = 0;
  context: any;
  resultMapper?: (lastStepResult: any) => any;
  result: any;
  logEnabled: boolean;
  logs: any[] = [];

  constructor(params: {
    title: string;
    steps: AIFlowStep[];
    context: any;
    resultMapper?: (lastStepResult: any) => any;
    logEnabled: boolean;
  }) {
    this.title = params.title;
    this.steps = params.steps;
    this.context = params.context;
    this.resultMapper = params.resultMapper;
    this.logEnabled = params.logEnabled;
  }

  async run() {
    try {
      this.currentStepIndex = 0;
      for (const step of this.steps) {
        let inputMessages;
        if (step.includeMessagesFromStepId) {
          const previousStep = this.steps.find(
            (step) => step.id === step.includeMessagesFromStepId,
          );
          inputMessages = previousStep?.inputMessages!;
          inputMessages.push({ role: 'assistant', content: step.lastResponse });
        }
        step.logger = this.logEnabled
          ? (message: string) => this.log({ stepLabel: step.label, message })
          : undefined;
        step.init(this.context, inputMessages);
        await step.run();
        this.context[step.id] = {
          ...(this.context[step.id] || {}),
          lastResult: step.lastResult,
        };
      }
      this.result = this.resultMapper
        ? this.resultMapper(this.steps[this.steps.length - 1].lastResult)
        : this.steps[this.steps.length - 1].lastResult;
    } catch (err) {
      //debugger;
      console.error('Error running AI flow', err);
    } finally {
      //debugger;
      if (this.logEnabled) {
        await saveAiFlowLogs(this.logs);
      }
    }
  }

  log(element: { stepLabel: string; message: string }) {
    this.logs.push({
      flow: this.title,
      time: new Date(),
      ...element,
    });
  }
}
