import { observer } from 'mobx-react-lite';
import { videoCreator } from '../../stores/VideoCreatorStore';
import styled from 'styled-components';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import Modal from '../common/Modal';
import CheckIcon from '../../svgs/CheckIcon';
import EllipsisIcon from '../../svgs/EllipsisIcon';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import SpinningLoading from '../SpinningLoading';
import DocumentIcon from '../../svgs/DocumentIcon';
import { runInAction } from 'mobx';
import CopyContentModal from '../stage/CopyContentModal';
import {
  handleDeleteBlogContent,
  handleLoadBlogContent,
  handleRenameBlogContent,
} from '../../utility/general';
import { BlogOrEmailContent } from '../../types.ts/general';
import ContentSelectorModal from './ContentSelectorModal';

type Props = {
  type: 'savedBlog' | 'savedEmail';
  entries: BlogOrEmailContent[];
  currentContent: BlogOrEmailContent | null;
  setCurrentContent: Dispatch<SetStateAction<BlogOrEmailContent | null>>;
  enableCreateAndEditing: boolean;
};

const ContentSelector: React.FC<Props> = observer((props) => {
  const saveType = props.type === 'savedBlog' ? 'saved_blog' : 'saved_email';
  const { entries, currentContent, setCurrentContent, enableCreateAndEditing } =
    props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [contentToEdit, toggleContentToEditModal] = useState<{
    type: 'save_as' | 'rename';
    content: BlogOrEmailContent | null;
  } | null>(null);
  const [actionProgress, setActionProgress] = useState<string>('');

  const [options, setOptionsModal] = useState<{
    position: number;
    content: BlogOrEmailContent | null;
  } | null>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(buttonRef, () => {
    setOptionsModal(null);
  });

  const loadContent = (content: BlogOrEmailContent) => {
    handleLoadBlogContent(content, setCurrentContent);
  };

  const getPos = () => {
    if (options && options.content) {
      const ellipsisButton = document.getElementById(
        `ellipsis-button-${options.content.id}`,
      );
      if (!ellipsisButton) return { top: 0, left: 0 };
      const wrapper = wrapperRef.current;
      const dropdown = dropdownRef.current;

      if (wrapper) {
        const ellipsisRect = ellipsisButton!.getBoundingClientRect();
        const wrapperRect = wrapper.getBoundingClientRect();

        const popupHeight = 76;
        const spaceBelow = window.innerHeight - ellipsisRect.top;
        const positionAbove = spaceBelow < popupHeight;

        const top = ellipsisRect.top - wrapperRect.top + 17;
        const left = ellipsisRect.left - wrapperRect.left + window.scrollX;
        const dropdownHeight = dropdown!.getBoundingClientRect().height;

        if (positionAbove) {
          return {
            bottom: spaceBelow - dropdownHeight + 12,
            left,
          };
        }
        return { top, left };
      }
    }
    return { top: 0, left: 0 };
  };

  async function deleteContent(contentId: number) {
    const currContentId = currentContent?.id;
    await handleDeleteBlogContent(
      contentId,
      currContentId,
      setOptionsModal,
      setDropdownOpen,
      setCurrentContent,
    );
  }

  async function renameContent(contentId: number, title: string) {
    await handleRenameBlogContent(
      contentId,
      title,
      props.type,
      saveType,
      toggleContentToEditModal,
      setOptionsModal,
      setDropdownOpen,
      setCurrentContent,
    );
  }

  function getColor(content: BlogOrEmailContent) {
    if (content.id === currentContent?.id) {
      return '#17C964';
    }
    return '#484848';
  }

  const renderCreateNew = () => (
    <CreateNew
      role="button"
      onClick={() => {
        runInAction(() => (videoCreator.selectedBlogContent = null));
        setDropdownOpen(false);
      }}
    >
      <CreateNewIcon>
        <DocumentIcon />
      </CreateNewIcon>
      <span>Create new blog</span>
    </CreateNew>
  );

  const renderDropdownItem = (content: BlogOrEmailContent) => (
    <DropdownItem
      key={content.id}
      onClick={() => {
        setDropdownOpen(false);
        loadContent(content);
      }}
    >
      <Title isSelected={true}>
        <ClipType>
          <Check color={getColor(content)}>
            <CheckIcon fillColor="#03041A" />
          </Check>
        </ClipType>
        <p>{content.title}</p>
      </Title>
      {enableCreateAndEditing && (
        <TimeAndEllipsis>
          <EllipsisButton
            id={`ellipsis-button-${content.id}`}
            onClick={(e) => {
              const rect = e.currentTarget.getBoundingClientRect();
              setOptionsModal({
                position: rect.top,
                content,
              });
              e.stopPropagation();
            }}
          >
            <EllipsisIcon fillColor="currentColor" width="10" />
          </EllipsisButton>
        </TimeAndEllipsis>
      )}
    </DropdownItem>
  );

  return (
    <Wrapper ref={wrapperRef}>
      <Item onClick={() => setDropdownOpen((open) => !open)}>
        <WrapperItemContent>
          <MainTitle>{currentContent?.title || 'Create new blog'}</MainTitle>
        </WrapperItemContent>
        <DropdownIcon isOpen={dropdownOpen}>
          <ArrowIcon />
        </DropdownIcon>
      </Item>
      {dropdownOpen && (
        <>
          <DropdownBackground
            onClick={() => setDropdownOpen(false)}
          ></DropdownBackground>
          <Dropdown ref={dropdownRef}>
            {!!actionProgress && (
              <SpinningLoading
                customStyle={{ height: '100%', width: '100%' }}
                text={actionProgress}
              />
            )}
            {enableCreateAndEditing && renderCreateNew()}
            {entries.map((content) => renderDropdownItem(content))}
          </Dropdown>
          {options?.content && (
            <ContentSelectorModal
              content={options.content}
              pos={getPos()}
              toggleContentToEditModal={toggleContentToEditModal}
              setOptionsModal={setOptionsModal}
              deleteContent={deleteContent}
            />
          )}
        </>
      )}

      {contentToEdit && (
        <Modal
          isOpen={contentToEdit !== null}
          closeModal={() => toggleContentToEditModal(null)}
        >
          <CopyContentModal
            name={contentToEdit.content?.title! ?? ''}
            tag="Blog"
            type={contentToEdit.type}
            onCancel={() => toggleContentToEditModal(null)}
            onSave={async (title) => {
              if (contentToEdit?.type === 'rename') {
                await renameContent(contentToEdit.content?.id!, title);
              }
            }}
          />
        </Modal>
      )}
    </Wrapper>
  );
});

export default ContentSelector;

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
  >
    <path d="M9 5.5L5 1.5L1 5.5" stroke="currentColor" strokeWidth="2" />
  </svg>
);

const WrapperItemContent = styled.div`
  flex: 1;
`;

const CreateNew = styled.div`
  display: flex;
  span {
    color: #f3e9d7;
    font-size: 12px;
    font-weight: 400;
  }
  border-bottom: 1px solid #484848;

  font-size: 12px;
  padding: 12px 0;
  margin: 0 16px;
  align-items: center;
  gap: 8px;
  position: relative;
  cursor: pointer;
`;
const CreateNewIcon = styled.div`
  color: #17c964;
`;

const DropdownIcon = styled.div<{ isOpen: boolean }>`
  width: 10px;
  height: 6px;
  margin-left: auto;
  display: flex;
  color: #f2d093;

  ${(props) => !props.isOpen && `rotate: 180deg;`}
`;

const Wrapper = styled.div`
  position: relative;
  min-width: 300px;
  max-width: 800px;
  height: fit-content;
`;

const EllipsisButton = styled.div`
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0 6px;
  margin-right: -6px;

  &:hover {
    color: #f2d093;
    scale: 1.1;
  }
`;

const MainTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

const Title = styled.div<{ isSelected: boolean }>`
  color: #f3e9d7;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  padding: 5px 0;

  gap: 4px;
  p {
    margin: 0;
  }
`;

const TimeAndEllipsis = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Item = styled.div`
  width: 100%;
  border: 1px solid #484848;
  border-radius: 8px;
  padding: 8px 16px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;

const Dropdown = styled.div`
  position: absolute;
  z-index: 1000;
  // top: 45px;
  width: 100%;
  border: 1px solid #484848;
  border-radius: 8px;
  background-color: #030419;
  padding: 8px 0;
  box-sizing: border-box;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media only screen and (max-height: 950px) {
    max-height: 450px;
  }
  @media only screen and (max-height: 810px) {
    max-height: 400px;
  }
  @media only screen and (max-height: 700px) {
    max-height: 350px;
  }
  @media only screen and (max-height: 600px) {
    max-height: 300px;
  }
  @media only screen and (max-height: 500px) {
    max-height: 250px;
  }
  @media only screen and (max-height: 430px) {
    max-height: 200px;
  }
  @media only screen and (max-height: 344px) {
    max-height: 150px;
  }
`;

const DropdownBackground = styled.div`
  position: fixed;
  z-index: 999;
  inset: 0;
  box-sizing: border-box;
`;

const DropdownItem = styled.div`
  color: #484848;
  font-size: 12px;
  font-weight: 200;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: #484848;
    color: #f2d093;
    p {
      color: #f2d093;
      font-weight: 700;
    }
  }

  &:not(:hover):not(:last-child)::after {
    content: '';
    position: absolute;
    height: 1px;
    width: calc(100% - 32px);
    left: 16px;
    background-color: #484848;
    bottom: -1px;
  }
`;

const Check = styled.div<{ color: string }>`
  display: flex;
  width: 10px;
  height: 10px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  background: ${(props) => props.color};
`;

const ClipType = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-style: italic;
  font-size: 10px;
  font-weight: 400;
`;

const Actions = styled.div<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: absolute;

  //   right: -80px;
  z-index: 2000;
  flex-direction: column;
  width: 110px;
  padding: 0;

  background: #03041a;
  border: 1px solid #484848;
  border-radius: 8px;
  font-size: 12px;
`;

const Action = styled.div`
  cursor: pointer;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;

  span {
    white-space: nowrap;
  }
  .icon {
    margin-left: auto;
    display: flex;
  }
  &:not(:first-child) {
    border-top: 1px solid #d9d9d9;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    background-color: #17c964;
    color: #03041a;
  }
`;

const RenameButton = styled(Action)``;

const DeleteButton = styled(Action)`
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: #ef5d6f;
  }
`;
