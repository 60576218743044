import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { AlbumQueryResult, PhotoArtifactTab } from '../../types.ts/story';
import useInitializeDatoUser from '../common/useInitializeDatoUser';
import { observer } from 'mobx-react-lite';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { gptService } from '../../services/ChatGPTService';
import SearchInput from '../sidepanel/SearchInput';
import StoryDashboardContent from './StoryDashboardContent';
import ClipDashboardContent from './ClipDashboardContent';
import { checkWordsInString } from '../../utility/general';

type Props = {
  params: {
    [k: string]: string;
  };
};

enum ContentTypes {
  story = 'Stories',
  clip = 'Clips',
}

function buildTargetUrl(
  params: URLSearchParams,
  storyId: string,
  playbackId: string | undefined,
): string {
  // if (params.get('showcase')) {
  //   const data = [] as string[];

  //   params.forEach((value, key) => {
  //     data.push(`${key}=${value}`);
  //   });

  //   return data.length ? '?' + data.join('&') : '';
  // } else {
  //   return `?storyId=${storyId}&env=${params.get('env')}&playbackId=${
  //     playbackId || ''
  //   }`;
  // }

  return `?storyId=${storyId}&env=${params.get('env')}&playbackId=${
    playbackId || ''
  }`;
}

const Dashboard: FC<Props> = observer((props) => {
  useInitializeDatoUser();
  const album = videoCreator.organization;
  const [stories, setStories] = useState<AlbumQueryResult['stories']>([]);
  const [allStories, setAllStories] = useState<AlbumQueryResult['stories']>([]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [selectedTab, setSelectedTab] = useState<ContentTypes>(
    ContentTypes.story,
  );

  useEffect(() => {
    if (!album?.id) return;
    (async () => {
      const stories = (await videoCreator.findManyStories(
        album.id,
      )) as AlbumQueryResult['stories'];
      setAllStories(stories);
      setStories(stories);
    })();
  }, [album]);

  function resetStoryStates(storyId: string) {
    const prevStoryId = urlSearchParams.get('storyId');
    if (prevStoryId !== storyId) {
      videoCreator.selectedPhotoAssets = {
        tab: PhotoArtifactTab.story,
        resource: undefined,
      };
      videoCreator.talkingPointContent = null;
      if (gptService.talkingPointController) {
        gptService.talkingPointController.abort();
      }
    }
  }

  const getDescription = (story: AlbumQueryResult['stories'][0]) => {
    const descriptionResponse = story?.aiResponse?.responses?.find(
      (response) => response.title === 'Description',
    );
    return descriptionResponse?.response?.toString() || '';
  };

  const handleSearchContent = (data: string) => {
    if (!data.length) {
      setStories(allStories);
      return;
    }
    const value = data.toLowerCase();
    const stories = allStories.filter((s) => {
      const summary = getDescription(s);
      return (
        checkWordsInString(s.storyTeller.name, value) ||
        checkWordsInString(s.title, value) ||
        checkWordsInString(summary, value)
      );
    });
    setStories(stories);
  };

  const handleSearchClipContent = (data: string) => {
    if (!data.length) {
      setStories(allStories);
      return;
    }
    const value = data.toLowerCase();

    const newStories = [];
    for (let s of allStories) {
      const isInStory =
        checkWordsInString(s.storyTeller.name, value) ||
        checkWordsInString(s.title, value);
      if (isInStory) {
        newStories.push(s);
        continue;
      }
      const clips = s.otherVideos.filter((v) => {
        return checkWordsInString(v.title, value);
      });
      newStories.push({ ...s, otherVideos: clips });
    }
    setStories(newStories);
  };

  return (
    <Main>
      <TopContent>
        <Title>Story Manager</Title>
        <Tabs>
          {Object.entries(ContentTypes).map(([k, v]) => (
            <Tab
              onClick={() => setSelectedTab(v)}
              isSelected={selectedTab === v}
            >
              {v}
            </Tab>
          ))}
        </Tabs>
        <SearchInput
          iconRight={true}
          placeholder={
            selectedTab === ContentTypes.story
              ? 'Search your stories'
              : 'Search your clips'
          }
          radius="10px"
          width="300px"
          handleAction={(text) => {
            if (selectedTab === ContentTypes.story) {
              handleSearchContent(text);
            } else if (selectedTab === ContentTypes.clip) {
              handleSearchClipContent(text);
            }
          }}
          autoSubmitDelay={500}
        />
      </TopContent>

      <Table>
        {(() => {
          switch (selectedTab) {
            case ContentTypes.story:
              return (
                <StoryDashboardContent
                  stories={stories}
                  resetStoryStates={resetStoryStates}
                  buildTargetUrl={buildTargetUrl}
                />
              );

            case ContentTypes.clip:
              return (
                <ClipDashboardContent
                  stories={stories}
                  resetStoryStates={resetStoryStates}
                  buildTargetUrl={buildTargetUrl}
                />
              );
          }
        })()}
      </Table>
    </Main>
  );
});

export default Dashboard;

const Main = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 80px;
  padding-bottom: 20px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  min-width: 440px;
`;
const Title = styled.h2`
  margin: 0;
  font-size: 32px;
  color: #f3e9d7;
  font-weight: 700;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Tabs = styled.div`
  display: flex;
  gap: 16px;
  margin-right: auto;
  margin-left: 50px;
`;

const Tab = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f3e9d7;
  cursor: pointer;
  &:hover {
    color: #f2d093;
    text-decoration: underline;
  }
  ${(props) =>
    props.isSelected &&
    css`
      color: #f2d093;
      text-decoration: underline;
    `}
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  font-family: 'Inter', sans-serif;
`;
