import { Client } from '@datocms/cma-client-browser';
import { ALBUM_QUERY, SHOWCASE_QUERY } from '../utility/gql';
import { GraphQLClient } from 'graphql-request';
import { Artifact, Showcase } from '../types.ts/story';
import ApiClient from '../apiClient/ApiClient';

export class AlbumRepository {
  private dClient: Client | ApiClient;
  private gqlClient: GraphQLClient;
  // private dClient: Client;

  constructor(dClient: Client | ApiClient, gqlClient: GraphQLClient) {
    this.gqlClient = gqlClient;
    this.dClient = dClient;
  }
  async findOneBySlug(slug: string): Promise<Showcase | null> {
    const response = (await this.gqlClient.request(SHOWCASE_QUERY, {
      slug,
    })) as { showcase?: Showcase };
    if (!response.showcase) return null;

    return response.showcase;
  }

  async findOne(id: string): Promise<Showcase | null> {
    const response = (await this.gqlClient.request(ALBUM_QUERY, {
      id,
    })) as { showcase?: Showcase };
    if (!response.showcase) return null;

    return response.showcase;
  }

  async update(showcase: Partial<Showcase> & { id: string }): Promise<void> {
    if (!showcase.id) {
      throw new Error('Organization id is required');
    }
    // console.log('showcase', JSON.stringify(showcase, null, 2));
    await this.dClient.items.update(showcase.id, {
      ...(showcase.title && {
        title: { en: showcase.title },
      }),

      ...(showcase.organizationArtifacts && {
        organization_artifacts: showcase.organizationArtifacts.map((photo) => ({
          upload_id: photo.id,
          ...(photo.title && { title: photo.title }),
        })),
      }),

      ...(showcase.organizationLogos && {
        organization_logos: showcase.organizationLogos.map((photo) => ({
          upload_id: photo.id,
          ...(photo.title && { title: photo.title }),
        })),
      }),

      ...(showcase.organizationArtifactsVideo && {
        organization_artifacts_video: showcase.organizationArtifactsVideo.map(
          (video) => ({
            upload_id: video.id,
            ...(video.title && { title: video.title }),
          }),
        ),
      }),

      ...(showcase.contributors && {
        contributors: showcase.contributors.map((c) => c.id),
      }),

      ...(showcase.lowerThirdTextBranding && {
        lower_third_text_branding: showcase.lowerThirdTextBranding.map(
          (b) => b.id,
        ),
      }),

      ...(showcase.karaokeTextBranding && {
        karaoke_text_branding: showcase.karaokeTextBranding.map((b) => b.id),
      }),

      ...(showcase.lastUsedTemplates && {
        last_used_templates: JSON.stringify(
          showcase.lastUsedTemplates,
          null,
          2,
        ),
      }),
    });
    await this.dClient.items.publish(showcase.id);
  }
}
