import styled, { css } from 'styled-components';
import ArrowUpIcon from '../../svgs/ArrowUpIcon';
import {
  ChangeEvent,
  Dispatch,
  FocusEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import DownloadIcon from '../../svgs/DownloadIcon';
import ArrowDown from '../../svgs/ArrowDown';
import ShareClipButton from '../common/ShareClipButton';
import { AssociatedVideo, Caption as CaptionType, ShareableImagePreviewType, ShareableImageType, Video, VideoClip } from '../../types.ts/story';
import ShareIcon from '../../svgs/ShareIcon';
import { videoCreator } from '../../stores/VideoCreatorStore';
import SocialStats from './SocialStats';
import ShareProfileView from './ShareProfileView';

import {
  delay,
  handleCopyToClipboard,
  handleDownloadMedia,
} from '../../utility/general';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import CopyIcon from '../../svgs/CopyIcon';

import { toPng } from 'html-to-image';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import { ShareModalAnimate, ShareModalDropdown, ShareModalDropdownItem } from '../../styles/mainStyle';
import RotatingSquare from '../../svgs/RotatingSquare';
import { observer } from 'mobx-react-lite';
import AutoResizeableMultilineInput from '../common/AutoResizeableMultilineInput';
import SpinningLoading from '../SpinningLoading';
import LinkIconAlt from '../../svgs/LinkIconAlt';
import { CreateButton } from '../stage/CreateButton';
import GraphIcon from '../../svgs/GraphIcon';
import CaptionService from '../../services/CaptionService';

type Platform = 'Twitter'
  | 'Facebook'
  | 'LinkedIn'
  | 'Instagram'
  | 'YouTube'
  | 'TikTok';


type Props = {
  name: Platform;
  clip?: VideoClip;
  shareable?: ShareableImagePreviewType;
  dimensions: string[];
  defaultDimension?: string;
  Icon: React.ReactNode;
  expandFirst?: boolean;
  showBorderBottom?: boolean;
  lowerLimit: number;
  upperLimit: number;
  onSelectDimension: (dimension: string) => void;
  expanded: string;
  onExpand: (name: string) => void;
  hideShareButton: boolean;
  imageRef?: React.RefObject<HTMLImageElement>;
  shareableImageToggle?: Dispatch<SetStateAction<ShareableImageType | null>>;
};

const insertNewLineBeforeHashtag = (text: string) => {
  const indexOfHashTag = text.indexOf('#');
  if (indexOfHashTag > 0 && text.charAt(indexOfHashTag - 1) !== '\n') {
    text = text.slice(0, indexOfHashTag) + '\n' + text.slice(indexOfHashTag);
  }
  return text;
};



const getCaptionData =
  (isVideoClip: boolean, clipId: string | undefined, shareableId: string | undefined, name: Platform) => {
    const captionService = new CaptionService()
    return captionService.getExistingCaptionData({
      platform: name.toLowerCase() as Lowercase<Platform>,
      label: isVideoClip ? 'otherVideos' : 'shareableImages',
      contentId: clipId || shareableId,
    })
  }

const ClipPostItem = observer((props: Props) => {
  const {
    name,
    clip,
    shareable,
    dimensions,
    defaultDimension,
    Icon,
    expandFirst,
    lowerLimit,
    upperLimit,
    onSelectDimension,
    imageRef
  } = props;

  const isVideoClip = !!clip;

  const { enableSharing } = useFlagsCombination();

  const [offPlatformLink, triggerOffPlatformLink] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedDimension, setSelectedDimension] = useState<string | null>(
    defaultDimension && dimensions.includes(defaultDimension)
      ? defaultDimension
      : dimensions[0],
  );
  const { contentStudioEnableClipPublish } = useFlagsCombination();

  let captionData = getCaptionData(isVideoClip, clip?.id, shareable?.id, name)
  let text = captionData?.platformData?.caption

  const buttonRef = useRef<HTMLButtonElement>(null);
  const textBodyRef = useRef<HTMLTextAreaElement>(null);
  const [paragraph, setParagraph] = useState(
    insertNewLineBeforeHashtag(text || ''),
  );

  const [title, setTitle] = useState(clip?.title || '');
  const [brandVoice, setBrandVoice] = useState<string>('');
  const [brandVoiceInput, toggleBrandVoiceInput] = useState<boolean>(false);

  const [dropdown, toggleDropdown] = useState<DOMRect | null>(null);
  const [profileModalMode, toggleProfileModal] = useState<{ mode: 'profile' | 'link' | 'share' | 'connect', pos: DOMRect } | null>(null);
  const [generateLoading, setGenerateLoading] = useState(false)

  function closeAll() {
    toggleDropdown(null)
    toggleProfileModal(null)
  }

  useOutsideAlerter(buttonRef, closeAll);

  const getShowcase = () => {
    if (showcases?.length && showcases[0]) return showcases[0]
    return null
  }

  // filter out sharedContent for this platform only
  const allReferencingSharedContents = clip ? clip._allReferencingSharedContents || [] : shareable?._allReferencingSharedContents || [];
  const platformSharedContent = allReferencingSharedContents.filter((post: any) => post.platform === name.toLowerCase())

  const alreadyShared = platformSharedContent && platformSharedContent.length > 0


  async function downloadMedia(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    e.preventDefault();
    e.stopPropagation();
    try {
      if (imageRef && imageRef.current) {
        setIsDownloading(true);
        const dataUrl = await toPng(imageRef.current);
        const media = {
          url: dataUrl,
          fileName: clip?.id,
          id: clip?.id,
        };
        await handleDownloadMedia(e, media);
      }

    } catch (error) {
      console.log(error);
    } finally {
      setIsDownloading(false);
    }
  }

  const handleChangeText = async (e: ChangeEvent<HTMLTextAreaElement>) => {
    const cursorPosition = e.target.selectionStart;
    const newParagraph = insertNewLineBeforeHashtag(e.target.value);
    setParagraph(newParagraph);

    if (!newParagraph?.trim()?.endsWith('#')) {
      await delay(10);
      textBodyRef.current?.setSelectionRange(cursorPosition, cursorPosition);
    }
  };

  useEffect(() => {
    if (!text) return
    setParagraph(insertNewLineBeforeHashtag(text));
  }, [text]);

  const renderDropdown = (dropdown: DOMRect) => {
    const top = dropdown.top + dropdown.height + 5;
    let left = dropdown.left - 255 + dropdown.width;
    const height = 210;
    const isBottom = top + height > window.innerHeight;

    return (
      <Dropdown top={top} left={left} isBottom={isBottom}>
        {renderShareButton()}
        {clip ? (<DropdownItem
          onClick={async () => {
            if (clip) {
              const textToCopy = `${process.env.REACT_APP_API_URL}/clips/${clip.slug}/${clip.hash}`;
              await handleCopyToClipboard(textToCopy);
              toggleDropdown(null)
            }
          }}
        >
          <span>Get Unlisted Link</span>
          <LinkIconAlt />

        </DropdownItem>)
          : null}
        <DropdownItem
          onClick={async () => {
            await handleCopyToClipboard(paragraph || '')
            toggleDropdown(null)
          }}
        >
          <span>Copy Caption Text</span>
          <CopyIcon strokeColor="currentColor" width="20" height="20" />
        </DropdownItem>
        <DropdownItem onClick={(e) => {
          const pos = e.currentTarget.getBoundingClientRect();
          toggleProfileModal({ mode: 'link', pos })
        }}>
          <span>Manage Social Platform</span>
          {profileModalMode?.mode === "link" && renderShareProfile()}
        </DropdownItem>

        {(name.toLowerCase() === 'facebook' || name.toLowerCase() === 'linkedin' || name.toLowerCase() === 'twitter' || name.toLowerCase() === 'x' || name.toLowerCase() === 'instagram') &&
          (<DropdownItem
            onClick={(e) => {
              e.stopPropagation()
              triggerOffPlatformLink(true)
              closeAll()
            }}>
            <span>Register Off-Platform Post</span>
            <GraphIcon />
          </DropdownItem>)}
      </Dropdown>
    );
  };

  const renderShareProfile = () => (
    <ShareProfileView
      platform={name.toLowerCase()}
      videoId={clip?.id}
      video={clip}
      sharableImageId={shareable?.id}
      storyId={videoCreator.story?.id}
      mediaUrl={file}
      title={title}
      text={paragraph}
      profiles={getShowcase()?.profiles ?? []}
      showcaseId={getShowcase()?.id}
      showcaseName={getShowcase()?.title}
      onClose={closeAll}
      mode={profileModalMode!.mode}
      pos={profileModalMode!.pos}
      imageRef={imageRef}
      Icon={Icon}
    />
  )

  const renderShareButton = () => {
    if (props.hideShareButton) return null;
    if (!alreadyShared && !props.hideShareButton) {
      return (
        <>
          <ShareClipButton
            allReferencingSharedContents={platformSharedContent}
            onClick={
              (pos: DOMRect, mode: "profile" | "link" | "share" | "connect") => toggleProfileModal({ mode, pos })
            }
            social={name.toLowerCase()}
            icon={Icon}
          />
          {(profileModalMode?.mode === "share" || profileModalMode?.mode === "profile") &&
            renderShareProfile()
          }
        </>
      );
    } else {
      return null;
    }
  };

  const showcases = videoCreator.story?._allReferencingShowcases;
  let file = '';
  let video: VideoClip | AssociatedVideo | undefined = clip;
  if (clip) {
    if (selectedDimension !== clip.aspectRatio) {
      video = clip.associatedVideos?.find(
        (v) => v.aspectRatio === selectedDimension,
      );
    }

    let videoUrl = video?.videoFilePrimary?.url;
    file = videoUrl || '';
  } else if (shareable) {
    file = shareable?.imageFile?.url || '';
  }

  const addActiveToArea = (e: HTMLElement) => {
    if (e.classList.contains('active')) return
    e.classList.add('active')
  }

  const handleGenerateCaption = async () => {
    setGenerateLoading(true)
    const data = {
      platform: name.toLowerCase() as Lowercase<Platform>,
      text: paragraph,
      label: isVideoClip ? 'otherVideos' : 'shareableImages' as "otherVideos" | "shareableImages",
      contentId: clip?.id || shareable?.id,
      id: captionData.platformData?.id,
      shareable
    }

    const captionService = new CaptionService()
    await captionService.regenerateCaptionStreamResponse(
      brandVoice,
      () => {
        toggleBrandVoiceInput(false);
        setGenerateLoading(false)
      },
      data,
      (savedId: string) => {
        if (savedId && props.shareableImageToggle && shareable) {
          props.shareableImageToggle(prev => ({
            ...prev!,
            id: savedId
          }))
        }
      }
    );
  }

  const handleTextBodyBlur =
    async (e: FocusEvent<HTMLTextAreaElement, Element>) => {
      e.currentTarget.parentElement?.classList.remove('active')
      const savedCaption = captionData?.platformData?.caption
      if (!paragraph) return
      if (savedCaption?.replace(/\n/g, '') !== paragraph.replace(/\n/g, '')) {
        const captionService = new CaptionService()
        await captionService.updateCaption(
          {
            platform: name.toLowerCase() as Lowercase<Platform>,
            text: paragraph,
            label: isVideoClip ? 'otherVideos' : 'shareableImages',
            contentId: clip?.id || shareable?.id,
            id: captionData.platformData?.id
          }
        )

      }
    }

  return (
    <Main showBorderBottom={props.showBorderBottom}>
      {generateLoading && <SpinningLoading
        text={!paragraph ? 'Generating caption...' : 'Regenerating caption...'}
      />}
      {props.expanded !== name ? (
        <Header>
          {Icon} <Caption>{name}</Caption>{' '}
          <ExpandCollapse
            onClick={() => {
              props.onExpand(name);
              if (selectedDimension) {
                onSelectDimension(selectedDimension);
              }
            }}
          >
            <ArrowDown
              width="14"
              height="8"
              viewBox="0 0 14 8"
              d="M1 1L7 7L13 1"
              strokeColor="#F2D093"
              strokeWidth="1"
            />
          </ExpandCollapse>
        </Header>
      ) : (
        <>
          <Header>
            {Icon} <Caption>{name}</Caption>
            <Dimensions>
              {dimensions.map((dimension) => (
                <Dimension
                  isSelected={selectedDimension === dimension}
                  onClick={() => {
                    setSelectedDimension(dimension);
                    onSelectDimension(dimension);
                  }}
                  key={dimension}
                >
                  {dimension}
                </Dimension>
              ))}
            </Dimensions>
            <ExpandCollapse onClick={() => props.onExpand('')}>
              <ArrowUpIcon />
            </ExpandCollapse>
          </Header>
          {videoCreator.story?._allReferencingShowcases && videoCreator.story?._allReferencingShowcases.length > 0 ?
            (
              <>
                {!paragraph ?
                  <div>
                    <Button onClick={handleGenerateCaption} backgroundColor='#F2D093' color="#03041A" fillRow>
                      <RotatingSquare strokeColor='currentColor' />
                      Generate Caption
                    </Button>
                  </div>
                  : brandVoiceInput ?
                    <div>
                      <AutoResizeableMultilineInput
                        placeholder='Describe the caption and tone you want to see (optional)'
                        hasOutline={false}
                        width="100%"
                        getValue={(text) => { setBrandVoice(text) }}
                      />
                      <Button onClick={handleGenerateCaption} backgroundColor='#F2D093' color="#03041A" fillRow>
                        <RotatingSquare strokeColor='currentColor' />
                        Regenerate
                      </Button>
                    </div>
                    :
                    <>
                      {name.toLowerCase() === "youtube" && (<TextInput
                        value={title}
                        placeholder={'Title'}
                        onChange={(e: any) => setTitle(e.target.value)}
                      ></TextInput>)}

                      <TextBodyArea
                        onClick={(e) => addActiveToArea(e.currentTarget)}
                        onBlur={(e) => e.currentTarget.classList.remove('active')}
                      >
                        <TextBody
                          ref={textBodyRef}
                          value={paragraph}
                          onChange={handleChangeText}
                          onFocus={e => addActiveToArea(e.currentTarget.parentElement!)}
                          onBlur={handleTextBodyBlur}

                        ></TextBody>
                        <SectionRegenerateButton
                          className="regenerate-action-button"
                          onClick={(e) => {
                            e.stopPropagation()
                            toggleBrandVoiceInput(true)
                          }}
                        >
                          <RotatingSquare
                            width="19"
                            height="19"
                            strokeColor="#17c964"
                          />
                        </SectionRegenerateButton>
                      </TextBodyArea>
                    </>
                }

                <TailContent>
                  <span className="note">
                    {paragraph.length}/{upperLimit} Characters (Recommended{' '}
                    {lowerLimit} - {upperLimit})
                  </span>
                </TailContent>


                <ButtonGroup>
                  {clip && contentStudioEnableClipPublish && (
                    <CreateButton isInClipsModal={true} />
                  )}
                  {!clip && <Button
                    color="#F2D093"
                    borderColor="#F2D093"
                    onClick={(e) => downloadMedia(e)}
                  >
                    {isDownloading ? (
                      <Animate />
                    ) : (
                      <DownloadIcon strokeColor="currentColor" />
                    )} Download</Button>}
                  {enableSharing && (<Button
                    ref={buttonRef}
                    color="#03041A"
                    backgroundColor="#F2D093"
                    disabled={!file}
                    onClick={(e) => {
                      const rect = e.currentTarget.getBoundingClientRect();
                      triggerOffPlatformLink(false)
                      toggleDropdown(rect);
                    }}
                  >
                    <ShareIcon strokeColor='currentColor' /> Share
                    {dropdown && renderDropdown(dropdown)}
                  </Button>)}
                </ButtonGroup>
                {offPlatformLink &&
                  <ShareProfileView
                    videoId={clip?.id}
                    sharableImageId={shareable?.id}
                    storyId={videoCreator.story?.id}
                    profiles={getShowcase()?.profiles ?? []}
                    showcaseId={getShowcase()?.id}
                    onClose={() => {
                      closeAll()
                      triggerOffPlatformLink(false)
                    }}
                    mode='connect'
                    platform={name.toLowerCase()}
                  />
                }
                {enableSharing && (
                  <SocialStats
                    type={clip ? "video" : "shareableContent"}
                    platform={name.toLowerCase()}
                    id={clip ? clip?.id : shareable?.id}
                    allReferencingSharedContents={platformSharedContent}
                    layout="share-modal"
                  />
                )}
              </>
            ) : (
              <>
                <WarningMessage>No Organization Owns This Story, Contact Arbor Support For Help</WarningMessage>
              </>
            )
          }
        </>
      )}
    </Main>
  );
});

export default ClipPostItem;

const Main = styled.div<{ showBorderBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top: 1px solid #484848;
  border-bottom: ${(props) => props.showBorderBottom && '1px solid #484848'};
  padding: 20px 15px 20px 15px;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const Caption = styled.span`
  font-size: 16px;
  font-weight: 400;
`;

const Dimensions = styled.div`
  display: flex;
  gap: 5px;
`;

const Dimension = styled.span<{ isSelected: boolean }>`
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #484848;
  font-size: 10px;
  cursor: pointer;
  ${(props) =>
    props.isSelected &&
    css`
      background-color: #484848;
      color: #f2d093;
    `}
`;

const ExpandCollapse = styled.button`
  outline: 0;
  border: 0;
  padding: 10px;
  background-color: transparent;
  margin-left: auto;
  cursor: pointer;
`;


const TextInput = styled.input`
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  padding: 10px;
  border: 0;
  text-align: left;
  font-size: 14px;
  line-height: 1.5;
  background-color: transparent;
  color: #f3e9d7;
  width: 100%;
  resize: none;
  height: 30px;
  margin: 10px 0;
  &:focus {
    border: 1px solid #484848;
    outline: 0;
  }
`;

const TextBodyArea = styled.div`
  width: 100%;
  display: flex; 
  gap: 2px;
  align-items: flex-start;
  margin: 10px 0;
  padding-top: 5px;
  border-radius: 8px;
  // &:active, &:focus {
  //   border: 1px solid #484848;
  //   outline: 0;
  // }
  &.active{
    border: 1px solid #484848;
  }
`

const TextBody = styled.textarea`
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  padding: 0 10px;
  border: 0;
  text-align: left;
  font-size: 14px;
  line-height: 1.5;
  background-color: transparent;
  color: #f3e9d7;
  width: 100%;
  resize: none;
  height: 120px;
  
  &:focus {
    // border: 1px solid #484848;
    // outline: 0;
    border: 0;
    outline: 0;
  }
`;

const WarningMessage = styled.div`
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding: 10px;
  border: 0;
  text-align: left;
  font-size: 14px;
  line-height: 1.5;
  color: rgb(241, 120, 182);
  width: 100%;
  height: 120px;
  margin: 10px 0;
`;

const TailContent = styled.div`
  display: flex;
  .note {
    font-weight: 700;
    color: #484848;
    font-size: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-left: 0;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: -20px;
`;

const Button = styled.button<{
  borderColor?: string;
  color?: string;
  backgroundColor?: string;
  width?: string;
  fillRow?: boolean;
}>`
  display: flex;
  margin: 10px auto;
  width: ${(props) => (props.fillRow ? '100%' : '50%')};
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.borderColor || '#484848'};
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor || '#030419'};
  color: ${(props) => props.color || '#f3e9d7'};
  flex: 1;
  position: relative;
  &:disabled {
    border: 1px solid #484848;
    background-color: #030419;
    color: #484848;
  }
`;

const Animate = styled(ShareModalAnimate)`
`;

const Dropdown = styled(ShareModalDropdown)`
  top: ${({ top, isBottom }) => !isBottom && top + 'px'};
  bottom: ${({ isBottom }) => isBottom && 0};
  left: ${({ left }) => left + 'px'}; 
  width: 255px;
`;

const DropdownItem = styled(ShareModalDropdownItem)`
  &:hover {
  color: #03041a;
  background-color: #17c964;
  }
`;

const SectionRegenerateButton = styled.button`
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  border: 1;
  display: flex;
  align-items: flex-end;
`