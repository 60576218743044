import React from 'react';
import { observer } from 'mobx-react-lite';
import { videoCreator } from '../../stores/VideoCreatorStore';
import TimelinePhotoIcon from '../../svgs/TimelinePhotoIcon';
import { ElementState } from '../../renderer/ElementState';
import {
  Word,
  Cursor,
  HighlightImageWrapper,
  HighlightImage,
} from './TranscriptionText';
import {
  TranscriptElement,
  getClosestNotRemovedElementIndexToRight,
} from '../../videoTranscriptionProcessor/utils';
import { computed } from 'mobx';

type TranscriptionBlockProps = {
  elements: TranscriptElement[];
  originalElements: TranscriptElement[];
  indexOffset: number;
  cursor: { from: number; to: number } | null;
  cursorHighlight: boolean;
};

type ElementProps = {
  value: string | JSX.Element | null;
  state: TranscriptElement['state'];
  index: number;
  isHighlighted: boolean;
  isPhotoHighlight: boolean;
};

export const Element = React.memo((props: ElementProps) => {
  const { value, state, index, isHighlighted, isPhotoHighlight } = props;
  if (state !== 'removed' && state !== 'cut') {
    return (
      <Word
        key={`element-${index}`}
        data-index={index}
        isPhotoHighlight={isPhotoHighlight}
        isHighlighted={isHighlighted}
        state={state}
        diff={true}
        isWhitespace={typeof value === 'string' && value.trim() === ''}
      >
        {value}
      </Word>
    );
  } else {
    return (
      <Word
        data-index={index}
        key={`removed-element-${index}`}
        state={state}
        diff={true}
      >
        {value}
      </Word>
    );
  }
});

type PhotoHighlightedBlockProps = TranscriptionBlockProps & {
  hasPhotoHighlight: boolean;
  onHighlightImageClick: () => void;
};

export const PhotoHighlightedBlock: React.FC<PhotoHighlightedBlockProps> =
  React.memo((props) => {
    const { hasPhotoHighlight, onHighlightImageClick, indexOffset } = props;

    return (
      <>
        <TranscriptionBlock {...props} />
        <HighlightImageWrapper>
          <HighlightImage
            isAssigned={hasPhotoHighlight}
            key={`highlight-${indexOffset}`}
            onClick={onHighlightImageClick}
          >
            <TimelinePhotoIcon
              width="10"
              height="10"
              strokeColor={hasPhotoHighlight ? '#fff' : '#bdbdbd'}
            ></TimelinePhotoIcon>
          </HighlightImage>
        </HighlightImageWrapper>
      </>
    );
  });

export const TranscriptionBlock: React.FC<TranscriptionBlockProps> = React.memo(
  (props) => {
    const { elements, originalElements, indexOffset, cursor, cursorHighlight } =
      props;

    return (
      <>
        {elements.map((el, index, arr) => {
          const elementIndex = index + indexOffset;
          let nextNotRemovedIndex = index + 1;
          if (!cursorHighlight) {
            nextNotRemovedIndex = getClosestNotRemovedElementIndexToRight(
              elementIndex + 1,
              arr,
            );
          }

          return (
            <>
              {cursor &&
              !cursorHighlight &&
              elementIndex === cursor.from &&
              arr[elementIndex - 1]?.state !== 'removed' &&
              arr[elementIndex - 1]?.state !== 'cut' ? (
                <Cursor data-index={cursor.from} />
              ) : null}
              <Element
                value={
                  el.state === 'replaced' &&
                  originalElements[el.initial_index]?.value !== el.value ? (
                    <>
                      <span
                        data-index={index}
                        style={{
                          color: '#b59b14',
                          textDecoration: 'line-through',
                        }}
                      >
                        {
                          videoCreator.originalTranscription?.elements[
                            el.initial_index
                          ]?.value
                        }
                      </span>
                      {el.value}
                    </>
                  ) : (
                    el.value
                  )
                }
                state={el.state}
                index={elementIndex}
                isHighlighted={Boolean(
                  cursor &&
                    cursorHighlight &&
                    elementIndex >= cursor.from &&
                    elementIndex < cursor.to,
                )}
                isPhotoHighlight={!!el.photo_highlight_id}
              />
              {cursor &&
              !cursorHighlight &&
              el.state !== 'removed' &&
              el.state !== 'cut' &&
              nextNotRemovedIndex > elementIndex + 1 &&
              nextNotRemovedIndex === cursor.from ? (
                <Cursor data-index={cursor.from} />
              ) : null}
            </>
          );
        })}
      </>
    );
  },
);

const createNewBlock = (
  currentBlock: TranscriptElement[],
  indexOffset: number,
  hasPhotoHighlight: boolean,
  photoHighlighElement: ElementState | undefined,
  currentBlockElementsState: TranscriptElement['state'] | 'photo_highlight',
) => {
  return {
    elements: currentBlock,
    indexOffset,
    ...(currentBlockElementsState === 'photo_highlight' && {
      hasPhotoHighlight,
      onHighlightImageClick: () =>
        videoCreator.setActiveElements(photoHighlighElement?.source.id),
    }),
  };
};

const shouldChangeBlock = (
  el: TranscriptElement,
  currentBlockType: TranscriptElement['state'] | 'photo_highlight',
  photoHighlighElementId: string | undefined,
) => {
  const startRemoved =
    (el.state === 'removed' || el.state === 'cut') &&
    currentBlockType !== 'removed' &&
    currentBlockType !== 'cut' &&
    currentBlockType !== 'photo_highlight';
  const startPhotoHighlight =
    el.photo_highlight_id && currentBlockType !== 'photo_highlight';
  const endRemoved =
    (!el.state || el.state === 'replaced' || el.state === 'added') &&
    (currentBlockType === 'removed' || currentBlockType === 'cut');
  const endPhotoHighlight =
    !el.photo_highlight_id && currentBlockType === 'photo_highlight';
  const changedPhotoHighlight =
    el.photo_highlight_id &&
    photoHighlighElementId &&
    photoHighlighElementId !== el.photo_highlight_id;
  return (
    startRemoved ||
    startPhotoHighlight ||
    endRemoved ||
    endPhotoHighlight ||
    changedPhotoHighlight
  );
};

export const divideElementsInTranscriptionBlocks = (
  elements: TranscriptElement[],
) => {
  // divide final transcription in blocks by elements state: removed/cut, added, unchanged, photo_highlight
  const blocks: Omit<
    TranscriptionBlockProps | PhotoHighlightedBlockProps,
    'originalElements' | 'cursor' | 'cursorHighlight'
  >[] = [];
  let currentBlock: TranscriptElement[] = [];
  let indexOffset = 0;
  let hasPhotoHighlight = false;
  let photoHighlighElement: ElementState | undefined;
  let currentBlockElementsState:
    | TranscriptElement['state']
    | 'photo_highlight' = videoCreator.finalTranscriptionElements?.at(0)?.state;

  for (let index = 0; index <= elements.length; index++) {
    const el = elements[index];
    if (
      !el ||
      shouldChangeBlock(
        el,
        currentBlockElementsState,
        photoHighlighElement?.source.id,
      )
    ) {
      if (currentBlock.length) {
        blocks.push(
          createNewBlock(
            currentBlock,
            indexOffset,
            hasPhotoHighlight,
            photoHighlighElement,
            currentBlockElementsState,
          ),
        );
        hasPhotoHighlight = false;
        if (!el) break; //handle end of array;

        // todo separate multiple photos by photo_highlight ids
        if (el.photo_highlight_id) {
          //debugger;
          photoHighlighElement = videoCreator.state!.elements.find(
            (e) => e.source.id === el.photo_highlight_id,
          );
          const imageElement = photoHighlighElement
            ? videoCreator.getImageElement(photoHighlighElement)
            : null;
          hasPhotoHighlight = !!imageElement?.source?.source;
        }
        currentBlock = [];
        indexOffset = index;
        currentBlockElementsState = el.state;
        if (el.photo_highlight_id) {
          currentBlockElementsState = 'photo_highlight';
        }
      }
      currentBlock.push(el);
    } else {
      currentBlock.push(el);
    }
  }
  return blocks;
};

type Props = {
  cursor: { from: number; to: number };
};

export const TranscriptionDiff: React.FC<Props> = observer(
  ({ cursor }: Props) => {
    const transcriptionBlocks = React.useMemo(() => {
      return computed(() =>
        divideElementsInTranscriptionBlocks(
          videoCreator.finalTranscriptionElements!,
        ),
      );
    }, []).get();

    const originalElements = videoCreator.originalTranscription!.elements;
    const isPlaying = videoCreator.isPlaying;

    return (
      <>
        {transcriptionBlocks.map((block, index) => {
          const blockCursor =
            cursor.from >= block.indexOffset &&
            cursor.from < block.indexOffset + block.elements.length
              ? cursor
              : null;

          if (
            'hasPhotoHighlight' in block &&
            'onHighlightImageClick' in block
          ) {
            return (
              <PhotoHighlightedBlock
                key={index}
                {...(block as PhotoHighlightedBlockProps)}
                cursor={blockCursor}
                cursorHighlight={Boolean(blockCursor && isPlaying)}
                originalElements={originalElements}
              />
            );
          } else {
            return (
              <TranscriptionBlock
                key={index}
                {...block}
                cursor={blockCursor}
                cursorHighlight={Boolean(blockCursor && isPlaying)}
                originalElements={originalElements}
              />
            );
          }
        })}
      </>
    );
  },
);
