import { Dispatch, FormEvent, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import ChatGPTService from '../../services/ChatGPTService';
import CreateIcon from '../../svgs/CreateIcon';
import { observer } from 'mobx-react-lite';
import AutoResizeableMultilineInput from './AutoResizeableMultilineInput';

type Props = {
  setIsLoading: (e: boolean) => void;
  supportedTitle: 'Blog' | 'Email';
  setImageSize: Dispatch<
    SetStateAction<Record<string, Record<'width' | 'height', number>> | null>
  >;
  gptService: ChatGPTService
};
const CreateBlogUI = observer((props: Props) => {
  const [value, setValue] = useState('');
  const title = props.supportedTitle;

  const handleAdjustInputHeight = (e: FormEvent<HTMLTextAreaElement>) => {
    const textarea = e.target as HTMLTextAreaElement;
    textarea.style.height = textarea.scrollHeight + 'px';
  };

  const handleClick = async () => {
    props.setImageSize(null);
    props.setIsLoading(true);

    await props.gptService.regenerateStreamResponse(
      props.supportedTitle,
      value,
      props.setIsLoading,
    );
  };
  return (
    <Main>
      <Title>Create a Blog</Title>
      <SubTitle>Turn your story into engaging written content.</SubTitle>
      <AutoResizeableMultilineInput
        placeholder='Describe your voice or provide instructions (Optional)'
        getValue={(text) => setValue(text)}
      />
      <Button onClick={handleClick}>
        <Icon>
          <CreateIcon />
        </Icon>
        Generate
      </Button>
    </Main>
  );
});

export default CreateBlogUI;
const Main = styled.div`
  display: grid;
  place-content: center;
  text-align: center;
`;
const Title = styled.h2`
  color: #f3e9d7;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin: 0;
`;
const SubTitle = styled.span`
  color: #f3e9d7;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 19.6px;
  margin-bottom: 20px;
`;

const Icon = styled.span`
  color: #03041a;
`;

const Description = styled.textarea`
  box-sizing: border-box;
  display: flex;
  padding: 15px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #484848;
  text-align: left;
  font-size: 12px;
  line-height: 1.5;
  background-color: transparent;
  color: #f3e9d7;
  width: 500px;
  resize: none;
  height: 50px;
`;

const Button = styled.button`
  display: flex;
  width: 240px;
  height: 40px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #17c964;
  outline: 0;
  border: 0;
  margin: 20px auto;
  cursor: pointer;
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
`;
