import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import {
  KARAOKE_TRACK_NUMBER,
  videoCreator,
} from '../../stores/VideoCreatorStore';
import { TextSettings } from './TextSettings';
import { ImageSettings } from './ImageSettings';
import { VideoSettings } from './VideoSettings';
import { AudioSettings } from './AudioSettings';
import { Music, Story } from '../../types.ts/story';
import ArtifactsAndAssets from './ArtifactsAndAssets';
import MusicLibrary from './MusicLibrary';
import SidePanelMenu from './SidePanelMenu';
import AIMusicProducer from './AIMusicProducer';
import AIStoryProducer from './AIStoryProducer';
import { AIProducerCard, SidebarOption } from '../../types.ts/general';
import AIPhotoPunchListProducer from './AIPhotoPunchListProducer';
import SpinningLoading from '../SpinningLoading';
import { VideoVersionHistory } from '../stage/VideoVersionHistory';
import AIClipProducer from './AIClipProducer';
import AIKaraokeTextProducer from './AIKaraokeTextProducer';
import VideoArtifacts from './VideoArtifacts';

type Props = {
  story: Story | undefined;
};

type MainPanelProps = {
  children: React.ReactNode;
  sidebarRef?: React.RefObject<HTMLDivElement>;
  id?: string;
};

const MainPanel: React.FC<MainPanelProps> = observer(
  (props: MainPanelProps) => (
    <Main onKeyDown={(e) => e.stopPropagation()} id={props.id}>
      <SidePanelMenu />
      <Panel ref={props.sidebarRef}>
        {/* todo: move to music specific component or rewrite all AI tabs to use same loader */}
        {videoCreator.musicProducerLoading && (
          <SpinningLoading
            positionTop="20px"
            text={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Finding music based on story</span>
                <span>mood and length...</span>
              </div>
            }
          />
        )}
        {props.children}
      </Panel>
    </Main>
  ),
);

export const RightSidePanel: React.FC<Props> = observer((props: Props) => {
  const activeElement = videoCreator.getActiveElement();
  const { sidebarOptions, aiProducerSubMenu, stockMusic } = videoCreator;
  const isBasicText =
    activeElement?.source?.type === 'text' &&
    activeElement.source.track !== KARAOKE_TRACK_NUMBER;
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sidebarOptions === SidebarOption.editing && !activeElement) {
      videoCreator.sidebarOptions = SidebarOption.photo;
    }
  }, [sidebarOptions, activeElement]);

  useEffect(() => {
    let e = videoCreator.openPhotoElementReplacementModal?.element;
    if (e && videoCreator.punchListData?.some((p) => p.id === e?.source?.id)) {
      videoCreator.aiProducerSubMenu = AIProducerCard.photo;
    }
  }, [sidebarOptions, activeElement]);

  //todo rework. this is a hack to get the photo punch list loader to show up when adding punch list manually
  useEffect(() => {
    if (
      sidebarOptions === SidebarOption.aiProducer &&
      (videoCreator.punchListLoading ||
        videoCreator.addedPunchListItemId ||
        videoCreator.savingStockPhoto)
    ) {
      videoCreator.aiProducerSubMenu = AIProducerCard.photo;
      // setSelectedCard(AIProducerCard.photo);
    }
  }, [
    sidebarOptions,
    videoCreator.punchListLoading,
    videoCreator.addedPunchListItemId,
  ]);

  // if (activeElement) {
  //   if (activeElement.source.type === 'text') {
  //     return (
  //       <MainPanel>
  //         <ScrollableArea>
  //           <TextSettings activeElement={activeElement} />
  //         </ScrollableArea>
  //       </MainPanel>
  //     );
  //   } else if (activeElement.source.type === 'audio') {
  //     return (
  //       <MainPanel>
  //         <ScrollableArea>
  //           <AudioSettings activeElement={activeElement} />
  //         </ScrollableArea>
  //       </MainPanel>
  //     );
  //   } else if (activeElement.source.type === 'image') {
  //     return (
  //       <MainPanel>
  //         <ScrollableArea>
  //           <ImageSettings activeElement={activeElement} />
  //         </ScrollableArea>
  //       </MainPanel>
  //     );
  //   } else if (activeElement.source.type === 'video') {
  //     return (
  //       <MainPanel>
  //         <ScrollableArea>
  //           <VideoSettings activeElement={activeElement} />
  //         </ScrollableArea>
  //       </MainPanel>
  //     );
  //   }
  // }

  const getAiProducerCard = () => {
    if (aiProducerSubMenu === AIProducerCard.music)
      return (
        <AIMusicProducer
          story={props.story}
          music={stockMusic}
          selectedCard={aiProducerSubMenu}
        />
      );
    else if (aiProducerSubMenu === AIProducerCard.clip)
      return <AIClipProducer />;
    else if (aiProducerSubMenu === AIProducerCard.story)
      return (
        <AIStoryProducer story={props.story} selectedCard={aiProducerSubMenu} />
      );
    else if (aiProducerSubMenu === AIProducerCard.photo)
      return (
        <AIPhotoPunchListProducer
          story={props.story}
          selectedCard={aiProducerSubMenu}
        />
      );
    else if (aiProducerSubMenu === AIProducerCard.karoke_text)
      return <AIKaraokeTextProducer ref={sidebarRef} />;
    else
      return (
        <AIMusicProducer
          story={props.story}
          music={stockMusic}
          selectedCard={aiProducerSubMenu}
        />
      );
  };

  if (sidebarOptions === SidebarOption.aiProducer) {
    return (
      <MainPanel sidebarRef={sidebarRef}>
        <ScrollableArea>{getAiProducerCard()}</ScrollableArea>
      </MainPanel>
    );
  }

  if (sidebarOptions === SidebarOption.music) {
    return (
      <MainPanel>
        <ScrollableArea>
          <MusicLibrary />
        </ScrollableArea>
      </MainPanel>
    );
  }
  if (sidebarOptions === SidebarOption.photo) {
    return (
      <MainPanel>
        <ScrollableArea>
          <ArtifactsAndAssets />
        </ScrollableArea>
      </MainPanel>
    );
  }

  if (sidebarOptions === SidebarOption.video) {
    return (
      <MainPanel>
        <ScrollableArea>
          <VideoArtifacts />
        </ScrollableArea>
      </MainPanel>
    );
  }

  if (sidebarOptions === SidebarOption.history) {
    return (
      <MainPanel sidebarRef={sidebarRef}>
        <ScrollableArea>
          <VideoVersionHistory sidebarRef={sidebarRef} />
        </ScrollableArea>
      </MainPanel>
    );
  }

  if (sidebarOptions === SidebarOption.text) {
    return (
      <MainPanel sidebarRef={sidebarRef}>
        <ScrollableArea>
          <TextSettings
            ref={sidebarRef}
            activeElement={isBasicText ? activeElement : null}
          />
        </ScrollableArea>
      </MainPanel>
    );
  }

  if (activeElement) {
    if (isBasicText) {
      return (
        <MainPanel sidebarRef={sidebarRef}>
          <ScrollableArea>
            <TextSettings activeElement={activeElement} ref={sidebarRef} />
          </ScrollableArea>
        </MainPanel>
      );
    } else if (activeElement.source.type === 'audio') {
      return (
        <MainPanel id="audio-settings-wrapper">
          <ScrollableArea>
            <AudioSettings activeElement={activeElement} />
          </ScrollableArea>
        </MainPanel>
      );
    } else if (videoCreator.isImageElement(activeElement)) {
      return (
        <MainPanel>
          <ScrollableArea>
            <ImageSettings activeElement={activeElement} />
          </ScrollableArea>
        </MainPanel>
      );
    } else if (activeElement.source.type === 'video') {
      return (
        <MainPanel>
          <ScrollableArea>
            <VideoSettings activeElement={activeElement} />
          </ScrollableArea>
        </MainPanel>
      );
    }
  }

  return (
    <MainPanel>
      <WelcomeScreen>
        <div>
          Welcome to the Arbor Video Editor.
          <br />
          <br />
          Feel free to slack anyone on the tech team with questions or issues.
        </div>
      </WelcomeScreen>
    </MainPanel>
  );
});

const Panel = styled.div`
  position: relative;
  flex: 1;
  border-radius: 8px;
  overflow-y: auto;
`;

const Main = styled.div`
  position: relative;
  padding: 15px 0 15px 20px;
  width: 350px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #404040;
`;

const ScrollableArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  padding: 0 10px 15px;
  display: flex;
  flex-direction: column;
`;

const WelcomeScreen = styled(ScrollableArea)`
  padding: 30px;
  height: 100%;
  justify-content: space-between;
`;

// const LinkButton = styled.div`
//   margin: 15px 0;
//   padding: 10px 0;
//   background: #292b2e;
//   border-radius: 5px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
// `;
