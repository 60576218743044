import type { AssociatedVideo, StoryVideo, VideoClip } from '../types.ts/story';

export const getAssociatedVideoWithStoryVideoField = (
  video: VideoClip,
  field: keyof StoryVideo['video'],
): AssociatedVideo | undefined => {
  const options = video.associatedVideos.filter(
    (v) => v.videoFilePrimary?.video?.[field],
  );
  if (!options.length) {
    return undefined;
  }
  return options.find((o) => o.aspectRatio === '16:9') || options[0];
};
