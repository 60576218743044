import './styles/globals.css';
import {
  connect,
  IntentCtx,
  RenderFieldExtensionCtx,
} from 'datocms-plugin-sdk';
import { render } from './utils/render';
import ConfigScreen from './entrypoints/ConfigScreen';
import FinalVideo from './entrypoints/FinalVideo';
import EditorEntry from './entrypoints/EditorEntry';
import 'datocms-react-ui/styles.css';
import UpdateClipSource from './entrypoints/UpdateClipSource';
import RestartTranscript from './entrypoints/RestartTranscript';

const isInIframe = window.location !== window.parent.location;

if (isInIframe) {
  connect({
    renderConfigScreen(ctx) {
      return render(<ConfigScreen ctx={ctx} />);
    },
    manualFieldExtensions(ctx: IntentCtx) {
      return [
        {
          id: 'videoEditor',
          name: 'Video Editor',
          type: 'addon',
          fieldTypes: ['link'],
        },
        {
          id: 'updateClipSource',
          name: 'Update Clip Source',
          type: 'addon',
          fieldTypes: ['file'],
        },
        {
          id: 'restartTranscriptGeneration',
          name: 'Restart Transcript Generation',
          type: 'addon',
          fieldTypes: ['link'],
        },
      ];
    },
    renderFieldExtension(
      fieldExtensionId: string,
      ctx: RenderFieldExtensionCtx,
    ) {
      switch (fieldExtensionId) {
        case 'videoEditor':
          return render(
            <>
              <FinalVideo ctx={ctx} />
            </>,
          );
        case 'updateClipSource':
          return render(
            <>
              <UpdateClipSource ctx={ctx} />
            </>,
          );
        case 'restartTranscriptGeneration':
          return render(
            <>
              <RestartTranscript ctx={ctx} />
            </>,
          );
      }
    },
  });
} else {
  render(<EditorEntry />);
}
